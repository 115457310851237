import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button } from '../../../components/shared';
import { getIcon } from '../../../utils/utils';

import flagSvg from '../../../assets/icons/flag.svg';
import styles from './ReviewFooter.module.css';

const ReviewFooter = ({ review, hasReportButton = false, customStyles = '', isRoleVisible }) => (
  <footer className={`${styles.footer} ${customStyles}`}>
    <div className={styles.categories}>
      <button className={styles.category} aria-label={`Filter by ${review.categories}`}>
        <span className={styles.text}>{`${getIcon(review.categories)} ${review.categories}`}</span>
      </button>
    </div>
    <hr className={styles.verticalDivider} />
    <address className={styles.postDate} rel=" author">
      {review.role && review.isRoleVisible ? (
        <>
          <span className={styles.role}>{review.role}</span>
          <span className={styles.circleDivider} />
        </>
      ) : null}
      {review.timestamp ? (
        <time pubdate="true" dateTime={review.timestamp} title={review.timestamp}>
          {dayjs(review.timestamp).format('MMMM, YYYY')}
        </time>
      ) : null}

      {hasReportButton ? (
        <Button customStyles={styles.report} onClick={() => console.log('Report!!')}>
          <img src={flagSvg} className={styles.reportIcon} alt="flag icon" role="presentation" />
          Report
        </Button>
      ) : null}
    </address>
  </footer>
);

ReviewFooter.propTypes = {
  customStyles: PropTypes.string,
  hasReportButton: PropTypes.bool,
  isRoleVisible: PropTypes.bool,
  review: PropTypes.shape({
    categories: PropTypes.string,
    role: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
};

export default ReviewFooter;
