import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../wrapper/Wrapper';

import styles from './CompanyBanner.module.css';

const CompanyBanner = ({ companyDetails, banner = null, logo = null }) => {
  const bannerSrc = banner ? banner : require(`../../../assets/banners/${companyDetails.banner}_banner_800x600.png`);

  return (
    <>
      <img className={styles.banner} src={bannerSrc} alt="background" role="presentation" />
      <section className={styles.hero}>
        <Wrapper customStyles={styles.wrapper}>
          {!companyDetails?.avatar ? (
            <img
              className={styles.logo}
              src={require(`../../../assets/logos/default_logo_200x200.png`)}
              alt={`${companyDetails?.name}'s logo`}
            />
          ) : (
            <img
              className={styles.logo}
              src={require(`../../../assets/logos/${companyDetails?.avatar}_logo_200x200.png`)}
              alt={`${companyDetails?.name}'s logo`}
            />
          )}
          <h1 className={styles.companyName}>{companyDetails.name}</h1>
          {companyDetails ? <p className={styles.slogan}>{companyDetails.slogan}</p> : null}
        </Wrapper>
      </section>
    </>
  );
};

CompanyBanner.propTypes = {
  companyDetails: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slogan: PropTypes.string,
  }),
  banner: PropTypes.string,
  logo: PropTypes.string,
};

export default CompanyBanner;
