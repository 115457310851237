import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '../../../components/shared';

import styles from './CompanySuccess.module.css';

const CompanySuccess = () => {
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/');
    }, 6000);

    return () => {
      clearTimeout(timeout);
      sessionStorage.clear();
    };
  }, [history]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        Thank you for your submission!
        <span role="img" aria-label="confetti emoji" className={styles.icon}>
          🎉
        </span>
      </h2>
      <p className={styles.text}>
        Your submission was successfully submitted and should be live very soon
        <br />
        You’ll be redirected to the home page in a few seconds...
      </p>
      <Link to="/" customStyles={styles.link}>
        Go to homepage
      </Link>
    </div>
  );
};

export default CompanySuccess;
