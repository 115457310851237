import React from 'react';
import PropTypes from 'prop-types';

import styles from './Details.module.css';

const Details = ({ company }) => {
  const { name, slogan, avatar, banner, workField, location, overallRating, totalReviews } = company;

  const dotToComma = (val) => val.toString().replace('.', ',');

  return (
    <div>
      <img
        className={styles.banner}
        src={require(`../../../assets/banners/${banner}_banner_800x600.png`)}
        alt="background"
        role="presentation"
      />
      <div className={styles.contentWrapper}>
        <img
          className={styles.avatar}
          src={require(`../../../assets/logos/${avatar}_logo_200x200.png`)}
          alt={`${name}'s logo`}
          role="presentation"
        />
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.slogan}>{slogan}</p>
      </div>
      <div className={styles.footer}>
        <div>
          <p className={styles.overallRating}>
            {overallRating ? dotToComma(overallRating) : '-'}
            <span className={styles.smallText}>/5</span>
          </p>
          <p className={styles.totalReviews}>{totalReviews} reviews</p>
        </div>
        <div className={styles.labelsWrapper}>
          <p className={styles.label}>
            <span className={styles.icon} role="img" aria-label="rocket emoji">
              🚀
            </span>
            {workField}
          </p>
          <p className={styles.label}>
            <span className={styles.icon} role="img" aria-label="globe emoji">
              🌎
            </span>
            {location}
          </p>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slogan: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    banner: PropTypes.string,
    workField: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    overallRating: PropTypes.number,
    totalReviews: PropTypes.number,
  }),
};

export default Details;
