import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button/Button';
import { CompanySearchModal, CompanyReviewConfirmationModal, NewCompanyConfirmationModal } from './content';

import crossBlackLightSvg from '../../../assets/icons/cross-black-light.svg';
import styles from './Modal.module.css';

const Modal = ({ customStyles = '', modalId, onClose, params }) => {
  useEffect(() => {
    document.querySelector('body').setAttribute('style', 'overflow-y: hidden');

    return () => {
      document.querySelector('body').removeAttribute('style');
    };
  }, []);

  const modalContent = (modalId) => {
    switch (modalId) {
      case 'CompanySearchModal':
        return <CompanySearchModal onClose={onClose} />;
      case 'CompanyReviewConfirmationModal':
        return <CompanyReviewConfirmationModal onClose={onClose} companyId={params} />;
      case 'NewCompanyConfirmationModal':
        return <NewCompanyConfirmationModal onClose={onClose} />;

      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay} onClick={() => onClose()} />
      <div className={`${styles.contentWrapper} ${customStyles}`}>
        <>
          <Button noStyle onClick={() => onClose()} customStyles={styles.closeButton}>
            <img src={crossBlackLightSvg} alt="cross icon" className={styles.closeIcon} />
          </Button>
          {modalContent(modalId)}
        </>
      </div>
    </div>
  );
};

Modal.propTypes = {
  customStyles: PropTypes.string,
  modalId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  params: PropTypes.any,
};

export default Modal;
