import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../shared/wrapper/Wrapper';
import Link from '../shared/link/Link';
import Button from '../shared/button/Button';
import AccountMenu from './account-menu/AccountMenu';

import logoSvg from '../../assets/images/logo/logo_lettering.svg';
import downArrowWhiteSvg from '../../assets/icons/arrow-down-white.svg';
import styles from './Header.module.css';

const Header = ({ isFixed = false, isUserLoggedIn = false, noLinks = false, onHandleModal }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  let settingsMenuTimeout = null;

  const handleSettingsMenuEnter = () => {
    clearTimeout(settingsMenuTimeout);
  };

  const handleSettingsMenuLeave = () => {
    settingsMenuTimeout = setTimeout(() => {
      setIsMenuVisible(false);
    }, 300);
  };

  const handleOnSearchClick = (modalId) => {
    onHandleModal(true, modalId);
  };

  return (
    <header className={styles.header}>
      <Wrapper>
        <div className={styles.navigationWrapper}>
          <Link to="/">
            <img src={logoSvg} alt="Logo" className={styles.logo} />
          </Link>
          {noLinks ? null : !isUserLoggedIn ? (
            <nav>
              <ul className={styles.accountWrapper}>
                <li>
                  <Link to="/" customStyles={styles.signUp}>
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link to="/" customStyles={styles.login}>
                    Login
                  </Link>
                </li>
              </ul>
            </nav>
          ) : (
            <div
              className={styles.settingsWrapper}
              onMouseEnter={handleSettingsMenuEnter}
              onMouseLeave={handleSettingsMenuLeave}
            >
              <Button noStyle onClick={() => setIsMenuVisible(!isMenuVisible)} customStyles={styles.settingsButton}>
                My Account
                <span className={styles.settingsArrow}>
                  <img src={downArrowWhiteSvg} alt="" aria-hidden />
                </span>
              </Button>
              {isMenuVisible ? <AccountMenu onSearchClick={handleOnSearchClick} /> : null}
            </div>
          )}
        </div>
      </Wrapper>
    </header>
  );
};

Header.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  isFixed: PropTypes.bool,
  noLinks: PropTypes.bool,
  onHandleModal: PropTypes.func.isRequired,
};

export default Header;
