import React from 'react';
import PropTypes from 'prop-types';
import CompanySearchBox from '../../../company-search-box/CompanySearchBox';

import styles from './CompanySearchModal.module.css';

const CompanySearchModal = ({ onClose }) => (
  <div className={styles.wrapper}>
    <p className={styles.title}>
      Company search{' '}
      <span role="img" aria-label="Telescope emoji">
        🔭
      </span>
    </p>
    <p className={styles.text}>Search for the company you're looking to review</p>
    <div className={styles.searchWrapper}>
      <CompanySearchBox placeholder="Search" linkTo="/review/" onClick={() => onClose()} />
    </div>
  </div>
);

CompanySearchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CompanySearchModal;
