import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../tooltip/Tooltip';

import styles from './RatingCirclesButton.module.css';

const generateCircles = () => {
  let i = 0;
  let circles = [];

  while (i < 5) {
    i++;

    circles.push(i);
  }

  return circles;
};

const RatingCirclesButton = ({ size = 16, onClick, defaultValue = null }) => {
  const circles = useMemo(() => generateCircles(), []);
  const [hoverValue, setHoverValue] = useState(defaultValue || 0);
  const [finalValue, setFinalValue] = useState(defaultValue || 0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const styleLeft = {
    width: `${size / 2}px`,
    height: size,
    borderRadius: `${size / 2}px 0 0 ${size / 2}px`,
  };

  const styleRight = {
    width: `${size / 2}px`,
    height: size,
    borderRadius: `0 ${size / 2}px ${size / 2}px 0`,
  };

  const handleMouseEnter = (value) => {
    setHoverValue(value);
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);

    if (!finalValue) {
      return setHoverValue(0);
    }

    return setHoverValue(finalValue);
  };

  const handleClick = (value) => {
    setFinalValue(value);
    onClick && onClick(value);
  };

  return (
    <>
      <Tooltip isVisible={isTooltipVisible} position="top" customStyles={styles.tooltip}>
        {finalValue || hoverValue}
      </Tooltip>
      <div className={styles.wrapper} onMouseLeave={handleMouseLeave}>
        {circles.map((value, index) => (
          <div key={index} className={styles.circleWrapper}>
            <button
              className={`${styles.half} ${styles.left} ${index + 0.5 <= hoverValue && styles.active}`}
              onMouseEnter={() => handleMouseEnter(value - 0.5)}
              onClick={() => handleClick(hoverValue)}
              style={styleLeft}
            />
            <button
              className={`${styles.half} ${styles.right} ${index + 1 <= hoverValue && styles.active}`}
              onMouseEnter={() => handleMouseEnter(value)}
              onClick={() => handleClick(hoverValue)}
              style={styleRight}
            />
          </div>
        ))}
      </div>
    </>
  );
};

RatingCirclesButton.propTypes = {
  defaultValue: PropTypes.number,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default RatingCirclesButton;
