import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Details from './details/Details';
import Ratings from './ratings/Ratings';
import Link from '../shared/link/Link';

import styles from './CompanyCard.module.css';

const CompanyCard = ({ company }) => {
  const { _id, details } = company;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Link to={`company/${_id}`} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
        {/* fetch top 5 categories of each company */}
        {company?.ratings.length > 0 ? (
          <div className={!isVisible ? styles.invisible : ''}>
            <Ratings ratings={company?.ratings} name={details.name} />
          </div>
        ) : null}
        <Details company={details} />
      </Link>
    </div>
  );
};

CompanyCard.propTypes = {
  company: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    details: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slogan: PropTypes.string.isRequired,
      workField: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
      banner: PropTypes.string.isRequired,
      overallRating: PropTypes.number.isRequired,
      totalReviews: PropTypes.number.isRequired,
    }).isRequired,
    reviews: PropTypes.array.isRequired,
  }).isRequired,
};

export default CompanyCard;
