import React from 'react';
import PropTypes from 'prop-types';

import styles from './Wrapper.module.css';

const Wrapper = ({ customStyles = '', children }) => (
  <div className={`${styles.wrapper} ${customStyles}`}>{children}</div>
);

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
  customStyles: PropTypes.string,
};

export default Wrapper;
