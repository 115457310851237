import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './RatingHalfCircle.module.css';

const RatingHalfCircle = ({ customStyles = '', circleColors, size }) => {
  const styleLeft = {
    width: `${size / 2}px`,
    height: size,
    borderRadius: `${size / 2}px 0 0 ${size / 2}px`,
  };

  const styleRight = {
    width: `${size / 2}px`,
    height: size,
    borderRadius: `0 ${size / 2}px ${size / 2}px 0`,
  };

  return (
    <span className={styles.wrapper}>
      <div className={`${styles.halfCircle} ${circleColors.colored} ${customStyles}`} style={styleLeft} />
      <div
        className={`${styles.halfCircle} ${styles['halfCircle--secondHalf']} ${circleColors.faded} ${customStyles}`}
        style={styleRight}
      />
    </span>
  );
};

RatingHalfCircle.propTypes = {
  circleColors: PropTypes.shape({
    colored: PropTypes.string.isRequired,
    faded: PropTypes.string.isRequired,
  }),
  customStyles: PropTypes.string,
  isFaded: PropTypes.bool,
  size: PropTypes.number,
};

export default RatingHalfCircle;
