import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingWheel } from '../../../components/shared/loading';
import { PreviewItem, RatingItem, Checkbox, Tooltip, ReviewFooter } from '../../../components/shared';
import { formatReview } from './utils';

import styles from './PreviewStep.module.css';

const RatingWrapper = ({ category }) => {
  const [ratingTooltip, setRatingTooltip] = useState(false);
  const categoryName = Object.keys(category)[0];
  const categoryValue = category[categoryName];

  return (
    <div className={styles.ratingWrapper}>
      <RatingItem
        direction="column"
        ratingItem={{ category: categoryName, value: categoryValue }}
        customStyles={styles.ratingItem}
        circlesCustomStyles={styles.circlesWrapper}
        size={22}
        tooltip={categoryValue}
        isColorized
      />
      {category.review ? (
        <span
          className={styles.reviewIndicator}
          onMouseEnter={() => setRatingTooltip(true)}
          onMouseLeave={() => setRatingTooltip(false)}
        >
          <Tooltip customStyles={styles.categoryTooltip} isVisible={ratingTooltip}>
            Contains written review
          </Tooltip>
        </span>
      ) : null}
    </div>
  );
};

const PreviewStep = ({ reviewStepData, ratingsStepData }) => {
  const [reviewFooter, setReviewFooter] = useState(null);
  const [isRoleVisible, setIsRoleVisible] = useState(true);
  const [isRoleTooltipVisilibe, setIsRoleTooltipVisilibe] = useState(false);
  const [newRatingsData, setNewRatingsData] = useState(null);
  const [newReviewsData, setNewReviewData] = useState(null);

  useEffect(() => {
    const timestamp = new Date();

    setNewRatingsData({ ...ratingsStepData });
    setNewReviewData({ ...reviewStepData, isRoleVisible });
    setReviewFooter({
      icons: [reviewStepData?.topicHighlight?.substr(0, 2)],
      categories: [reviewStepData?.topicHighlight],
      role: ratingsStepData.rolePicked,
      timestamp,
    });
  }, [isRoleVisible, ratingsStepData, reviewStepData]);

  const handleHideRole = (value) => {
    sessionStorage.setItem('previewStep', JSON.stringify({ isRoleVisible: !value }));
    setIsRoleVisible(!value);
  };

  return (
    <div className={styles.wrapper}>
      {!newRatingsData ? (
        <LoadingWheel customStyles={styles.loading} size="medium" />
      ) : (
        <>
          <PreviewItem label="Your role">
            <span aria-hidden className={styles.icon}>
              👔
            </span>
            {newRatingsData.rolePicked}
          </PreviewItem>
          <PreviewItem label="Year">
            <span aria-hidden className={styles.icon}>
              🗓
            </span>
            {newRatingsData.yearPicked}
          </PreviewItem>
          <PreviewItem label="Ratings">
            <div className={styles.columnsWrapper}>
              <div className={styles.leftColumn}>
                {newRatingsData.ratedCategories.map((category, index) =>
                  index < newRatingsData.ratedCategories.length / 2 ? (
                    <RatingWrapper key={index} category={category} />
                  ) : null
                )}
              </div>
              <div className={styles.rightColumn}>
                {newRatingsData.ratedCategories.map((category, index) =>
                  index >= newRatingsData.ratedCategories.length / 2 ? (
                    <RatingWrapper key={index} category={category} />
                  ) : null
                )}
              </div>
            </div>
          </PreviewItem>
          <PreviewItem label="Overall score" customStyles={styles.overallScore}>
            <span aria-hidden className={styles.icon}>
              🏆
            </span>
            {newReviewsData?.finalScore}
          </PreviewItem>
          {newReviewsData.review ? (
            <PreviewItem label="Overall review" customStyles={styles.overallReview}>
              <>
                {newReviewsData.title ? <p className={styles.title}>{newReviewsData.title}</p> : null}

                {formatReview(newReviewsData.review, styles.bold)}
                {reviewFooter ? (
                  <ReviewFooter
                    review={reviewFooter}
                    isRoleVisible={isRoleVisible}
                    customStyles={styles.reviewFooter}
                  />
                ) : null}
                <Checkbox id="hide_role" onClick={handleHideRole}>
                  Hide my role from the review
                  <span
                    className={styles.questionMarkTooltipLabel}
                    onMouseEnter={() => setIsRoleTooltipVisilibe(true)}
                    onMouseLeave={() => setIsRoleTooltipVisilibe(false)}
                  >
                    ?
                    <Tooltip customStyles={styles.questionMarkTooltip} isVisible={isRoleTooltipVisilibe}>
                      All reviews are posted anonymously
                      <br />
                      The review author's name is your role
                    </Tooltip>
                  </span>
                </Checkbox>
              </>
            </PreviewItem>
          ) : null}
        </>
      )}
    </div>
  );
};

RatingWrapper.propTypes = {
  category: PropTypes.object.isRequired,
};

export default PreviewStep;
