import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sortBy, getIcon } from '../../../utils/utils';

// Styles
import styles from './Ratings.module.css';
import RatingCircles from '../../shared/rating-circles/RatingCircles';

const Ratings = ({ ratings, name }) => {
  const memoizedSortedRatings = useMemo(() => sortBy(ratings, 'value', 'desc'), [ratings]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.name}>{name}</p>
      <div className={styles.ratingsWrapper}>
        {memoizedSortedRatings.map((rating, index) =>
          index < 5 ? (
            <div key={index} className={styles.ratingRow}>
              <span className={styles.nameWrapper}>
                <span className={styles.icon}>{getIcon(rating.category)}</span>
                <span className={styles.name}>{rating.category}</span>
              </span>
              <span className={styles.value}>
                <RatingCircles rating={rating.value} isStatic isColorized />
              </span>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

Ratings.propTypes = {
  name: PropTypes.string.isRequired,
  ratings: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      review: PropTypes.string,
    })
  ).isRequired,
};

export default Ratings;
