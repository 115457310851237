import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RatingCircle from './rating-circle/RatingCircle';
import RatingHalfCircle from './rating-half-circle/RatingHalfCircle';
import RatingCirclesButton from './rating-circles-button/RatingCirclesButton';
import { generateCirclesArray, ratingColor, formatRating } from './utils';

// Styles
import styles from './RatingCircles.module.css';

const RatingCircles = ({ rating = null, customStyles = '', isClickable, isColorized, size = 16, onClick }) => {
  const circles = useMemo(() => generateCirclesArray(formatRating(rating)), [rating]);
  if (isClickable) {
    return (
      <RatingCirclesButton size={size} customStyles={customStyles.halfCircle} defaultValue={rating} onClick={onClick} />
    );
  } else {
    return circles.map((circleType, index) => {
      const circleColors = {
        colored: styles[`circle--${ratingColor(rating)}`],
        faded: styles[`circleFaded--${ratingColor(rating)}`],
      };

      if (circleType === 'full') {
        return (
          <RatingCircle
            key={index}
            circleColor={isColorized ? circleColors.colored : null}
            size={size}
            customStyles={customStyles.circle}
          />
        );
      } else if (circleType === 'half') {
        return (
          <RatingHalfCircle
            key={index}
            circleColors={isColorized ? circleColors : null}
            size={size}
            customStyles={customStyles.halfCircle}
          />
        );
      }

      return (
        <RatingCircle
          key={index}
          circleColor={isColorized ? circleColors.faded : null}
          size={size}
          customStyles={customStyles.circle}
        />
      );
    });
  }
};

RatingCircles.propTypes = {
  isClickable: PropTypes.bool,
  isColorized: PropTypes.bool,
  isStatic: PropTypes.bool,
  onClick: PropTypes.func,
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number,
};

export default RatingCircles;
