import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useRouteMatch, useLocation, Switch, Route, Redirect, useHistory, Prompt } from 'react-router-dom';
import Header from '../../components/header/Header';
import { CompanyBanner, Wrapper, Tooltip, Link, Button, StepCounter } from '../../components/shared';
import { LoadingWheel } from '../../components/shared/loading';
import { getSlugFromUrl } from '../../utils/utils';
import RatingStep from './rating-step/RatingStep';
import ReviewStep from './review-step/ReviewStep';
import PreviewStep from './preview-step/PreviewStep';
import SuccessStep from './success-step/SuccessStep';

// import { companies } from '../../mocks';

import styles from './CompanyReviewPage.module.css';

const CompanyReviewPage = ({ onHandleModal }) => {
  const [company, setCompany] = useState(null);
  const [activeStep, setActiveStep] = useState('ratings');
  const [isStepValidated, setIsStepValidated] = useState(false);
  const [isNextToolTip, setIsNextToolTip] = useState(false);
  const [prefillData, setPrefillData] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const allSteps = ['ratings', 'review', 'preview'];

  const { url } = useRouteMatch();
  const { companyId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_COMPANY_DETAILS, {
          method: 'POST',
          body: JSON.stringify({ companyId }),
        });
        const company = await response.json();

        setCompany(company);
      } catch (error) {
        console.error('Server error: ', error);
      }
    };

    fetchCompanyDetails();

    return () => {
      sessionStorage.clear();
    };
  }, [companyId]);

  useEffect(() => {
    const urlStep = getSlugFromUrl(pathname);
    const ratingsStepData = sessionStorage.getItem('ratingsStep');
    const reviewStepData = sessionStorage.getItem('reviewStep');

    setPrefillData({ ratingsStepData: JSON.parse(ratingsStepData), reviewStepData: JSON.parse(reviewStepData) });

    setActiveStep(urlStep);
  }, [pathname]);

  const nextTooltipText = () => {
    switch (activeStep) {
      case 'ratings':
        return 'You must pick a role, year and rate at least 5 categories';
      case 'review':
        return 'An overall score must be provided';
      case 'preview':
        return null;
      default:
        return null;
    }
  };

  const getPreviousStep = () => (activeStep === 'preview' ? 'review' : 'ratings');
  const getNextStep = () => (activeStep === 'ratings' ? 'review' : 'preview');

  const saveData = (stepData, stepName) => {
    stepData !== 'preview' && sessionStorage.setItem(`${stepName}Step`, JSON.stringify(stepData));
    setHasUnsavedChanges(sessionStorage.getItem('ratingsStep') || sessionStorage.getItem('reviewStep'));
  };

  const handleRatingStepChange = (isStepValid, ratingsStepData) => {
    isStepValid() ? setIsStepValidated(true) : setIsStepValidated(false);
    saveData(ratingsStepData, 'ratings');
  };

  const handleReviewsStepChange = (isStepValid, reviewsStepData) => {
    isStepValid ? setIsStepValidated(true) : setIsStepValidated(false);
    saveData(reviewsStepData, 'review');
  };

  const handleNextStep = () => {
    setIsStepValidated(false);
  };

  const handleFormValidation = () => {
    // Highlight form errors
  };

  const handleCancel = () => {
    const pathTo = activeStep === 'ratings' ? '/' : getPreviousStep();
    if (pathTo === '/') {
      sessionStorage.clear();
    }

    history.push(pathTo);
  };

  const shouldShowLeavePagePrompt = (location) => {
    const regex = new RegExp(`/review/${companyId}`, 'g');
    const reviewPath = location.pathname.match(regex);

    if (hasUnsavedChanges && !reviewPath) {
      return 'Leave page? Your changes will not be saved';
    }

    return true;
  };

  return (
    <>
      <Header isFixed onHandleModal={onHandleModal} />
      {!company ? (
        <LoadingWheel size="medium" />
      ) : (
        <>
          {activeStep !== 'success' ? <CompanyBanner companyDetails={company.details} /> : null}
          <Prompt message={(location) => shouldShowLeavePagePrompt(location)} />
          <Wrapper customStyles={styles.content}>
            {activeStep !== 'success' ? (
              <div className={styles.stepWrapper}>
                <StepCounter steps={allSteps} currentStep={activeStep} />
              </div>
            ) : null}
            <Switch>
              <Route path={`/review/${companyId}/ratings`}>
                <RatingStep
                  company={company}
                  onChange={handleRatingStepChange}
                  prefillData={prefillData.ratingsStepData ? prefillData.ratingsStepData : null}
                />
              </Route>
              <Route path={`/review/${companyId}/review`}>
                {!sessionStorage.getItem('ratingsStep') ? (
                  <Redirect to={`${url}/ratings`} />
                ) : (
                  <ReviewStep
                    onChange={handleReviewsStepChange}
                    prefillData={prefillData.reviewStepData ? prefillData.reviewStepData : null}
                  />
                )}
              </Route>
              <Route path={`/review/${companyId}/preview`}>
                {!sessionStorage.getItem('ratingsStep') || !sessionStorage.getItem('reviewStep') ? (
                  <Redirect to={`${url}/ratings`} />
                ) : (
                  <PreviewStep
                    path={`/review/${companyId}/preview`}
                    reviewStepData={prefillData.reviewStepData}
                    ratingsStepData={prefillData.ratingsStepData}
                  />
                )}
              </Route>
              <Route path={`/review/${companyId}/success`}>
                {!sessionStorage.getItem('ratingsStep') || !sessionStorage.getItem('reviewStep') ? (
                  <Redirect to={`${url}/ratings`} />
                ) : (
                  <SuccessStep path={`/review/${companyId}/success`} company={company.details.name} />
                )}
              </Route>
              <Route path={`/review/${companyId}`}>
                <Redirect to={`${url}/ratings`} />
              </Route>
            </Switch>
            {activeStep !== 'success' ? (
              <div className={styles.footerButtonsWrapper}>
                <Button onClick={handleCancel} customStyles={styles.footerCancel}>
                  {activeStep === 'ratings' ? 'Cancel' : 'Back'}
                </Button>
                {activeStep !== 'preview' ? (
                  <span
                    className={styles.nextWrapper}
                    onMouseEnter={() => !isStepValidated && setIsNextToolTip(true)}
                    onMouseLeave={() => !isStepValidated && setIsNextToolTip(false)}
                  >
                    {!isStepValidated ? (
                      <Button disabled onClick={handleFormValidation}>
                        Next
                      </Button>
                    ) : (
                      <Link
                        to={`/review/${companyId}/${getNextStep()}`}
                        customStyles={styles.nextButton}
                        onClick={handleNextStep}
                      >
                        Next
                      </Link>
                    )}

                    {!isStepValidated && nextTooltipText() ? (
                      <Tooltip customStyles={styles.tooltip} isVisible={isNextToolTip}>
                        {nextTooltipText()}
                      </Tooltip>
                    ) : null}
                  </span>
                ) : (
                  <Button
                    customStyles={styles.submitButton}
                    onClick={() => onHandleModal(true, 'CompanyReviewConfirmationModal', companyId)}
                  >
                    Submit
                  </Button>
                )}
              </div>
            ) : null}
          </Wrapper>
        </>
      )}
    </>
  );
};

CompanyReviewPage.propTypes = {
  onHandleModal: PropTypes.func.isRequired,
};

export default CompanyReviewPage;
