import React, { useState } from 'react';
import PropTypes from 'prop-types';

import upVoteSvg from '../../../../../assets/icons/upvote.svg';
import upVoteDarkSvg from '../../../../../assets/icons/upvote-dark.svg';
import upVoteBlueSvg from '../../../../../assets/icons/upvote-blue.svg';

import styles from './UpVote.module.css';

const UpVote = ({ children, isVoted = false, onUpVote }) => {
  const [buttonHover, setButtonHover] = useState(false);

  const upVoteIcon = () => {
    if (!isVoted) {
      return buttonHover ? upVoteDarkSvg : upVoteSvg;
    }

    return upVoteBlueSvg;
  };

  const formatTotalVotes = (total) => (Number(total) > 1000 ? Number(total) / 1000 + 'k' : Number(total));

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.upVote}
        onMouseEnter={() => setButtonHover(true)}
        onMouseLeave={() => setButtonHover(false)}
        onClick={() => onUpVote(!isVoted)}
        aria-label="Up vote"
      >
        <img src={upVoteIcon(isVoted, buttonHover)} className={styles.icon} alt="Up arrow" />
      </button>
      <div className={`${styles.totalVotes} ${isVoted ? styles.voted : ''}`}>{formatTotalVotes(children)}</div>
    </div>
  );
};

UpVote.propTypes = {
  children: PropTypes.number,
  isVoted: PropTypes.bool,
  onUpVote: PropTypes.func.isRequired,
};

export default UpVote;
