import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../shared';

import styles from './NewCompanyConfirmationModal.module.css';

const NewCompanyConfirmationModal = ({ onClose, companyId }) => {
  const [isCompanyBeingSubmitted, setIsCompanyBeingSubmitted] = useState(false);
  const history = useHistory();

  const submitCompany = async (summaryStep, localizationStep, socialStep) => {
    const payload = {
      avatar: summaryStep?.avatar,
      banner: summaryStep?.banner,
      description: summaryStep?.companyNameDescription,
      field: localizationStep.companyField,
      language: localizationStep.companyLanguage,
      linkedin: socialStep?.companyLinkedIn,
      location: localizationStep.companyLocation,
      name: summaryStep.companyName,
      origin: localizationStep.companyOrigin,
      size: localizationStep.companySize,
      slogan: summaryStep.companySlogan,
      twitter: socialStep?.companyTwitter,
      website: socialStep.companyWebsite,
    };

    try {
      setIsCompanyBeingSubmitted(true);

      const response = await fetch(process.env.REACT_APP_SUBMIT_NEW_COMPANY, {
        method: 'POST',
        body: JSON.stringify(payload),
      });

      return response;
    } catch (error) {
      console.error('Server error: ', error);
    }
  };

  const handleSubmit = async () => {
    const summaryStep = JSON.parse(sessionStorage.getItem('summaryStep'));
    const localizationStep = JSON.parse(sessionStorage.getItem('localizationStep'));
    const socialStep = JSON.parse(sessionStorage.getItem('socialStep'));

    const response = await submitCompany(summaryStep, localizationStep, socialStep);

    if (response.status === 200) {
      setIsCompanyBeingSubmitted(false);
      onClose();
      history.push(`/new-company/success`);
    } else {
      console.error('Some error occurred, please try again');
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.modalTitle}>Confirmation</h3>
      <p className={styles.modalText}>
        Once your created company profile is submitted it will go under review but you won't be able to edit or delete
        it
        <br />
        <br />
        Are you ready to submit this company profile?
      </p>
      <div className={styles.buttonWrapper}>
        <Button noStyle customStyles={styles.cancel} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button customStyles={styles.submit} onClick={handleSubmit} isLoading={isCompanyBeingSubmitted}>
          Submit
        </Button>
      </div>
    </div>
  );
};

NewCompanyConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NewCompanyConfirmationModal;
