import React from 'react';
import PropTypes from 'prop-types';

import styles from './PreviewItem.module.css';

const PreviewItem = ({ label, customStyles = '', children }) => (
  <div className={`${styles.detailsWrapper} ${customStyles}`}>
    <dt className={styles.label}>{label}</dt>
    <dd className={styles.value}>{children}</dd>
  </div>
);

PreviewItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
  customStyles: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default PreviewItem;
