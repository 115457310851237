import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './RatingCircle.module.css';

const RatingCircle = ({ customStyles = '', circleColor, size }) => {
  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size / 2}px`,
  };

  return <div className={`${styles.circle} ${circleColor} ${customStyles}`} style={style} />;
};

RatingCircle.propTypes = {
  circleColor: PropTypes.string.isRequired,
  customStyles: PropTypes.string,
};

export default RatingCircle;
