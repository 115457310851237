const hasHalfCircle = (rating) => !Number.isInteger(rating);

/**
 *
 * @TODO: I'm sure this can be improved and simplified
 */
export const generateCirclesArray = (rating) => {
  const truncatedRating = Math.trunc(rating);
  let circles = [];
  let isHalfCirclePlaced = false;

  for (let i = 0; i < 5; i++) {
    if (truncatedRating > i) {
      circles.push('full');
    } else {
      if (hasHalfCircle(rating) && !isHalfCirclePlaced) {
        circles.push('half');
        isHalfCirclePlaced = true;
      } else {
        circles.push(false);
      }
    }
  }

  return circles;
};

export const ratingColor = (rating) => {
  if (!rating) {
    return 'default';
  }

  if (rating < 2) {
    return 'red';
  } else if (rating < 4) {
    return 'yellow';
  } else if (rating >= 4) {
    return 'green';
  }
};

/**
 * Converts a float number into an integer or .5 value
 * @param {number} value number
 * @returns {number} integer or .5 value
 */
export const formatRating = (value) => (value % 1 >= 0.5 ? `${Math.trunc(value)}.5` : Math.trunc(value));
