import React from 'react';
import PropTypes from 'prop-types';

import styles from './Checkbox.module.css';

const Checkbox = ({ id, size = 18, onClick, children }) => (
  <div className={styles.wrapper}>
    <div className={styles.checkboxWrapper}>
      <input
        type="checkbox"
        id={id}
        name={id}
        className={styles.checkboxInput}
        onClick={(e) => onClick && onClick(e.target.checked)}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
      <div className={styles.checkbox} style={{ width: `${size}px`, height: `${size}px` }} />
    </div>
    <label htmlFor={id} className={styles.label}>
      {children}
    </label>
  </div>
);

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default Checkbox;
