import React from 'react';
import PropTypes from 'prop-types';

import styles from './Textbox.module.css';

const Textbox = ({
  customStyles = '',
  defaultValue = '',
  icon = null,
  id = null,
  isPassword,
  label = null,
  maxLength = null,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder = null,
  type = 'text',
}) => {
  let labelElement = null;

  if (icon) {
    return (
      <div className={styles.textboxContainer}>
        <span className={styles.icon}>
          <img src={icon} role="presentation" alt="" />
        </span>
        <input
          type={isPassword ? 'password' : type}
          placeholder={placeholder}
          maxLength={maxLength}
          onFocus={(e) => onFocus && onFocus(e.target.value)}
          onBlur={(e) => onBlur && onBlur(e)}
          onChange={(e) => onChange && onChange(e.target.value)}
          onKeyDown={(e) => onKeyDown && e.key === 'Escape' && onKeyDown()}
          defaultValue={defaultValue}
          className={`${styles.textbox} ${customStyles} ${styles['textbox--hasIcon']}`}
        />
      </div>
    );
  }

  if (label) {
    labelElement = (
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    );
  }

  return (
    <>
      {labelElement}
      <input
        id={id}
        type={isPassword ? 'password' : type}
        placeholder={placeholder}
        maxLength={maxLength}
        onChange={(e) => onChange && onChange(e.target.value)}
        onFocus={(e) => onFocus && onFocus(e.target.value)}
        onBlur={(e) => onBlur && onBlur(e.target.value)}
        onKeyDown={(e) => onKeyDown && e.key === 'Escape' && onKeyDown()}
        defaultValue={defaultValue}
        className={`${styles.textbox} ${customStyles}`}
      />
    </>
  );
};

Textbox.propTypes = {
  customStyles: PropTypes.string,
  defaultValue: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  isPassword: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

export default Textbox;
