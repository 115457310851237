import { useState } from 'react';

/**
 * Validates a form given the conditions by checking if all the conditions are true
 * @param {array} conditions Rules to be validated
 */
const useFormValidation = (conditions) => {
  const [isStepValidated, setIsStepValidated] = useState(false);

  const handleFormValidation = () => {
    const areConditionsMet = conditions.find((condition) => !condition);

    return areConditionsMet === undefined ? setIsStepValidated(true) : setIsStepValidated(false);
  };

  return [isStepValidated, handleFormValidation];
};

export default useFormValidation;
