import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormValidation } from '../../../components/hooks';
import { convertArrayToDropdown } from '../../../utils/utils';
import { Textbox, Button, Dropdown, Link } from '../../../components/shared';
import languages from '../../../data/languages.json';

import styles from './CompanyLocalization.module.css';

const CompanyLocalization = ({
  prefillData,
  onSaveStepData,
  updateCompanySize,
  updateLanguage,
  updateLocation,
  updateOrigin,
  updateWorkField,
  validationRules,
}) => {
  const [isStepValidated, handleFormValidation] = useFormValidation(validationRules);
  const workFields = ['foo', 'bar', 'another', 'one', 'web'];
  const companySize = [
    '1-9 employees',
    '10-49 employees',
    '50-199 employees',
    '200-499 employees',
    '500-999 employees',
    '1000-5000 employees',
    '5000+ employees',
  ];
  const currentYear = new Date().getFullYear();
  const companyLanguages = languages.map((language) => language.language);

  useEffect(() => {
    handleFormValidation();
  }, [handleFormValidation, updateLocation, updateWorkField, updateCompanySize, updateLanguage]);

  const handleLocationChange = (val) => {
    updateLocation(val);
  };

  const handleWorkFieldChange = (val) => {
    updateWorkField(val);
  };

  const handleCompanySizeChange = (val) => {
    updateCompanySize(val);
  };

  const handleLanguageChange = (val) => {
    updateLanguage(val);
  };

  const handleCompanyOriginChange = (val) => {
    updateOrigin(val);
  };

  return (
    <>
      <Textbox
        label="Location"
        id="location"
        placeholder="Earth"
        defaultValue={prefillData?.companyLocation}
        customStyles={styles.textbox}
        onChange={handleLocationChange}
      />
      <Dropdown
        label="Work Field"
        placeholder="Field"
        options={convertArrayToDropdown(workFields.sort())}
        defaultValue={prefillData?.companyField}
        customStyles={styles.textbox}
        onChange={handleWorkFieldChange}
      />
      <Dropdown
        label="Company Size"
        placeholder="Size"
        options={convertArrayToDropdown(companySize)}
        defaultValue={prefillData?.companySize}
        customStyles={styles.textbox}
        onChange={handleCompanySizeChange}
      />
      <Textbox
        label="Origin Year"
        placeholder={currentYear}
        defaultValue={prefillData?.companyOrigin}
        customStyles={styles.textbox}
        onChange={handleCompanyOriginChange}
        maxLength={4}
      />
      <Dropdown
        label="Company Official Language"
        placeholder="Language"
        options={convertArrayToDropdown(companyLanguages)}
        defaultValue={prefillData?.companyLanguage}
        customStyles={styles.textbox}
        onChange={handleLanguageChange}
      />
      <div className={styles.buttonWrapper}>
        <Link to="/new-company/summary" customStyles={styles.backButton}>
          Back
        </Link>
        {!isStepValidated ? (
          <Button disabled onClick={handleFormValidation}>
            Next
          </Button>
        ) : (
          <Link to="/new-company/social" onClick={onSaveStepData} customStyles={styles.nextButton}>
            Next
          </Link>
        )}
      </div>
    </>
  );
};

CompanyLocalization.propTypes = {
  prefillData: PropTypes.shape({
    companyLocation: PropTypes.string,
    companyField: PropTypes.string,
    companySize: PropTypes.string,
    companyLanguage: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func.isRequired,
  updateCompanySize: PropTypes.func.isRequired,
  updateLanguage: PropTypes.func.isRequired,
  updateLocation: PropTypes.func.isRequired,
  updateOrigin: PropTypes.func.isRequired,
  updateWorkField: PropTypes.func.isRequired,
  validationRules: PropTypes.array.isRequired,
};

export default CompanyLocalization;
