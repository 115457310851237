import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RatingItem } from '../../../components/shared';
import { sortObjectArrayBy } from '../../../utils/utils';
import { LoadingWheel } from '../../../components/shared/loading';

import styles from './CompanyRatings.module.css';

const CompanyRatings = ({ companyId }) => {
  const [ratings, setRatings] = useState(null);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_COMPANY_RATINGS, {
          method: 'POST',
          body: JSON.stringify({ companyId }),
        });
        const ratings = await response.json();

        setRatings(ratings);
      } catch (error) {
        console.error('Server error: ', error);
      }
    };

    fetchCompanyDetails();
  }, [companyId]);

  return (
    <div>
      <ul>
        {!ratings ? (
          <LoadingWheel size="medium" />
        ) : ratings.length > 0 ? (
          sortObjectArrayBy(ratings, 'value', 'desc').map((ratingItem, index) => (
            <li key={index}>
              <RatingItem
                ratingItem={ratingItem}
                hoverEffect
                customStyles={styles.ratingItem}
                circlesCustomStyles={styles.circlesWrapper}
                tooltip={ratingItem.value}
              />
            </li>
          ))
        ) : (
          <p className={styles.noResults}>
            This company doesnt have enough ratings to visualize yet{' '}
            <span role="img" aria-label="magnifying glass emoji">
              🔍
            </span>
          </p>
        )}
      </ul>
    </div>
  );
};

CompanyRatings.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyRatings;
