import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReviewFooter } from '../../../../components/shared';
import UpVote from './up-vote/UpVote';
import ExpandableText from './expandable-text/ExpandableText';

import styles from './Review.module.css';

const Review = ({ review }) => {
  const [isReviewVoted, setIsReviewVoted] = useState(false);

  return (
    <article className={styles.wrapper}>
      <div className={styles.leftColumn}>
        <UpVote onUpVote={(vote) => setIsReviewVoted(vote)} isVoted={isReviewVoted}>
          {review.upVotes}
        </UpVote>
      </div>
      <div className={styles.rightColumn}>
        <h2 className={styles.title}>{review.title}</h2>
        <ExpandableText className={styles.text}>{review.text}</ExpandableText>
        <ReviewFooter review={review} hasReportButton />
      </div>
    </article>
  );
};

Review.propTypes = {
  review: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    categories: PropTypes.array.isRequired,
    finalScore: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
    isReported: PropTypes.bool.isRequired,
    isRoleVisible: PropTypes.bool.isRequired,
    ratings: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        review: PropTypes.string.isRequired,
      })
    ).isRequired,
    relevantYear: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    text: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    title: PropTypes.string,
    upVotes: PropTypes.number.isRequired,
  }),
};

export default Review;
