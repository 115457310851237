import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Textarea, Radio, Textbox, Dropdown, Tooltip } from '../../../components/shared';
import reviewTemplates from '../../../data/reviewTemplates';
import { categoriesList } from '../../../data';

import styles from './ReviewStep.module.css';

const ReviewStep = ({ onChange, prefillData }) => {
  const memoizedPrefillData = useMemo(() => prefillData, [prefillData]);
  const [finalScore, setFinalScore] = useState(memoizedPrefillData?.finalScore || null);
  const [template, setTemplate] = useState('No template');
  const [review, setReview] = useState(memoizedPrefillData?.review || null);
  const [topicHighlight, setTopicHighlight] = useState(memoizedPrefillData?.topicHighlight || '');
  const [title, setTitle] = useState(memoizedPrefillData?.title || '');
  const [topicHighlightTooltip, setTopicHighlightTooltip] = useState(false);

  useEffect(() => {
    const isStepValidated = () => !!finalScore;

    if (finalScore || review || topicHighlight || title) {
      onChange(isStepValidated(), { finalScore, review, topicHighlight, title });
    }
  }, [finalScore, review, topicHighlight, title, onChange]);

  const convertCategoriesToDropdown = (list) =>
    list.map((item) => ({ name: `${item.icon} ${item.category}`, value: item.category }));

  const handleHighlightUpdate = (value) => {
    setTopicHighlight(value);
  };

  const handleTitleUpdate = (value) => {
    setTitle(value);
  };

  const handleReviewUpdate = (value) => {
    setReview(value);
  };

  const RatingButtons = () => {
    const scoreValues = [1, 2, 3, 4, 5];

    const handleFinalScore = (score) => {
      setFinalScore(score);
    };

    return (
      <div className={styles.ratingWrapper}>
        {scoreValues.map((value, index) => (
          <button
            key={index}
            className={`${styles.scoreButton} ${finalScore >= value ? styles.active : ''}`}
            onClick={() => handleFinalScore(value)}
          >
            {value}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>Overall score</label>
      <RatingButtons />
      <label className={styles.label}>Overall review</label>
      <div className={styles.radioWrapper}>
        <Radio
          id="no_template_radio1"
          name="review_template"
          customStyles={styles.radio}
          onChange={() => setTemplate('noTemplate')}
          defaultChecked
        >
          No template
        </Radio>
        <Radio
          id="no_template_radio2"
          name="review_template"
          customStyles={styles.radio}
          onChange={() => setTemplate('prosCons')}
        >
          Pros and cons
        </Radio>
      </div>
      <Textarea
        placeholder="Share your experience (optional)"
        customStyles={styles.textarea}
        onBlur={handleReviewUpdate}
      >
        {review || reviewTemplates[template]}
      </Textarea>
      <div className={styles.topicsTooltipWrapper}>
        <Dropdown
          label="Highlighted topic"
          placeholder="Categories"
          options={convertCategoriesToDropdown(categoriesList)}
          customStyles={styles.dropdown}
          defaultValue={topicHighlight}
          onChange={handleHighlightUpdate}
        />
        <span
          className={styles.topicsTooltipLabel}
          onMouseEnter={() => setTopicHighlightTooltip(true)}
          onMouseLeave={() => setTopicHighlightTooltip(false)}
        >
          ?
          <Tooltip customStyles={styles.topicsTooltip} isVisible={topicHighlightTooltip} position="top">
            A category relevant to the review you just wrote
          </Tooltip>
        </span>
      </div>
      <div className={styles.textboxWrapper}>
        <label className={styles.label}>Review title</label>
        <Textbox placeholder="Title" customStyles={styles.textbox} defaultValue={title} onBlur={handleTitleUpdate} />
      </div>
    </div>
  );
};

ReviewStep.propTypes = {
  onChange: PropTypes.func.isRequired,
  prefillData: PropTypes.object,
};

export default ReviewStep;
