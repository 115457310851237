import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Tooltip.module.css';

const Tooltip = ({ children, customStyles = '', isVisible = false, position = 'top' }) => (
  <div className={`${styles.wrapper} ${styles[position]} ${!isVisible ? styles.hidden : ''} ${customStyles}`}>
    {children}
  </div>
);

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  customStyles: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['bottom', 'top']),
};

export default Tooltip;
