import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components/shared';

import arrowRightSvg from '../../../../../assets/icons/arrow-right.svg';
import arrowPurpleRightSvg from '../../../../../assets/icons/arrow-purple-right.svg';
import styles from './ExpandableText.module.css';

const ExpandableText = ({ className, children }) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [isButtonHighlighted, setIsButtonHighlighted] = useState(false);
  const MAX_CHARS = 275;

  if (!isTextExpanded && children.length > MAX_CHARS) {
    const truncatedText = children.substr(0, MAX_CHARS);

    return (
      <>
        <p className={className}>{truncatedText}…</p>
        <Button
          ariaLabel="expand text"
          customStyles={styles.seeMoreButton}
          onClick={() => setIsTextExpanded(!isTextExpanded)}
          onMouseEnter={() => setIsButtonHighlighted(true)}
          onMouseLeave={() => setIsButtonHighlighted(false)}
        >
          See More
          <img
            src={isButtonHighlighted ? arrowPurpleRightSvg : arrowRightSvg}
            className={styles.icon}
            alt="right arrow"
            role="presentation"
          />
        </Button>
      </>
    );
  }

  return <p className={className}>{children}</p>;
};

ExpandableText.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ExpandableText;
