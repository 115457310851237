import { categoriesList } from '../data';

export const sortBy = (list, category, orderType = 'asc') => {
  if (orderType === 'desc') {
    return list.sort((a, b) => b[category] - a[category]);
  }

  return list.sort((a, b) => a[category] - b[category]);
};

export const getSlugFromUrl = (url) => {
  const splitUrl = url.split('/');

  return splitUrl[splitUrl.length - 1];
};

export const sortObjectArrayBy = (list, category, orderType = 'asc') =>
  list.sort((a, b) => {
    if (isNaN(a[category])) {
      const categoryA = a[category].toLowerCase();
      const categoryB = b[category].toLowerCase();

      if (orderType === 'asc') {
        if (categoryA < categoryB) {
          return -1;
        }

        if (categoryA > categoryB) {
          return 1;
        }

        return 0;
      } else {
        if (categoryA > categoryB) {
          return -1;
        }

        if (categoryA < categoryB) {
          return 1;
        }

        return 0;
      }
    } else {
      if (orderType === 'asc') {
        return a[category] - b[category];
      } else {
        return b[category] - a[category];
      }
    }
  });

export const truncateText = (text, maxChars) => {
  if (text.length > maxChars) {
    const truncatedText = text.substr(0, maxChars);

    return `${truncatedText}…`;
  }

  return text;
};

/**
 * Replaces a value from a property of an object within an array
 * @param {array} arr Array to map
 * @param {string} property Property of the value to be changed
 * @param {string} oldVal Value to be found and replaced
 * @param {string} newVal New value of the property
 * @returns {array}
 */
export const updateObjByKeyValue = (arr, property, oldVal, newVal) => {
  return arr.map((prop) => {
    if (prop[property] === oldVal) {
      return {
        ...prop,
        [property]: newVal,
      };
    }

    return { ...prop };
  });
};

/**
 * Find a value within and object array
 * @param {array} list Array to be searched
 * @param {string} property Property to look for
 * @param {string} value Value we're looking for
 */
export const findInObjectArray = (list, property, value) => list.find((item) => item[property] === value);

export const indexOfObjectArrayKey = (list, keyValue) =>
  list.indexOf(list.find((item) => item[keyValue])) >= 0 ? list.indexOf(list.find((item) => item[keyValue])) : null;

/**
 * Replaces a category string for its corresponding icon
 * @param {string} category
 */
export const getIcon = (categoryName) => {
  const flatCategoryName = Array.isArray(categoryName) ? categoryName[0] : categoryName;
  const category = findInObjectArray(categoriesList, 'category', flatCategoryName);

  return category ? category.icon : null;
};

/**
 * Standard debounce function
 * @param {function} callback
 * @param {number} timeout delay in ms (defaults to 250ms)
 */

export const debounce = (callback, delay = 250) => {
  let timeOutId;

  return function () {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(callback, delay);
  };
};

/**
 * Directly converts an array list to a dropdown ready object
 * @param {array} list dropdown options list
 */

export const convertArrayToDropdown = (list) => list.map((item) => ({ name: item, value: item }));
