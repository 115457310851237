import React from 'react';
import PropTypes from 'prop-types';
import CompanyCard from '../company-card/CompanyCard';

// Styles
import styles from './CompanyList.module.css';

const CompanyList = ({ companies }) => (
  <div className={styles.list}>
    {companies.map((company, index) => (
      <CompanyCard key={index} company={company} />
    ))}
  </div>
);

CompanyList.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.object.isRequired,
      ratings: PropTypes.array.isRequired,
    })
  ).isRequired,
};

export default CompanyList;
