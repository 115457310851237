import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Link = ({ to, customStyles = '', onMouseEnter, onMouseLeave, onClick, children }) => (
  <ReactLink to={to}>
    <div
      className={customStyles}
      onMouseEnter={onMouseEnter ? (e) => onMouseEnter(e) : null}
      onMouseLeave={onMouseLeave ? (e) => onMouseLeave(e) : null}
      onClick={(e) => onClick && onClick(e)}
    >
      {children}
    </div>
  </ReactLink>
);

Link.propTypes = {
  customStyles: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  to: PropTypes.string.isRequired,
};

export default Link;
