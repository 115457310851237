import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button/Button';
import Link from '../../shared/link/Link';

import styles from './AccountMenu.module.css';

const AccountMenu = ({ onSearchClick }) => (
  <ul className={styles.accountMenu}>
    <li className={`${styles.item} ${styles.username}`}>foobar@mail.com</li>
    <li className={styles.accountMenuItem}>
      <Button noStyle onClick={() => onSearchClick('CompanySearchModal')} customStyles={styles.item}>
        <span className={styles.itemIcon} role="img" aria-label="Hand writing emoji">
          ✍️
        </span>
        Add review
      </Button>
    </li>
    <li className={styles.accountMenuItem}>
      <Link to="/new-company" customStyles={styles.item}>
        <span className={styles.itemIcon} role="img" aria-label="Office emoji">
          🏢
        </span>
        New company
      </Link>
    </li>
    <li className={styles.accountMenuItem}>
      <Link to="#" customStyles={styles.item}>
        <span className={styles.itemIcon} role="img" aria-label="Cogwheel emoji">
          ⚙️
        </span>
        Settings
      </Link>
    </li>
    <li className={styles.accountMenuItem}>
      <Button noStyle onClick={() => false} customStyles={styles.item}>
        <span className={styles.itemIcon} role="img" aria-label="Wave emoji">
          👋
        </span>
        Sign out
      </Button>
    </li>
  </ul>
);

AccountMenu.propTypes = {
  onSearchClick: PropTypes.func.isRequired,
};

export default AccountMenu;
