export const yearsInService = (origin) => {
  const currentYear = new Date().getFullYear();
  const difference = currentYear - origin;
  let yearArray = [];
  let i = 0;

  while (i <= difference) {
    yearArray.push(currentYear - (difference - i));
    i++;
  }

  return yearArray;
};

export const arrayToObject = (list, keys) => {
  return list.map((element) => {
    return keys.reduce((acc, value) => {
      return {
        ...acc,
        [value]: element,
      };
    }, {});
  });
};

export const splitCategories = (roles) => {
  const totalRolesMiddle = roles.length / 2;
  const left = roles.slice(0, totalRolesMiddle);
  const right = roles.slice(totalRolesMiddle, roles.length);

  return {
    left,
    right,
  };
};

const updateExistingCategoryProp = (list, category, rating) => {
  return list.map((prop) => {
    return prop[category] ? { [category]: rating, review: prop.review } : prop;
  });
};

/**
 * Adds a new category rating or replaces its value in case of it existing already
 * @param {array} categories
 * @param {string} category
 * @param {number} rating
 */
export const updateCategoryRating = (categories, category, rating) => {
  const hasDuplicateProperty = !!categories.find((obj) => obj[category]);

  if (hasDuplicateProperty) {
    return updateExistingCategoryProp(categories, category, rating);
  }

  return [...categories, { [category]: rating, review: null }];
};

/**
 * Updates the review test of an existing category
 * @param {array} categories
 * @param {string} category
 * @param {string} reviewText
 */
export const updateCategoryReview = (categories, category, reviewText) =>
  categories.map((prop) => (prop[category] ? { [category]: prop[category], review: reviewText } : prop));

export const ratingStepValidation = (role, year, ratedCategories) => {
  const MINIMUM_RATED_CATEGORIES = 5;

  const isRolePicked = () => role && role.length > 0;
  const isYearPicked = () => year && year.length > 0;
  const hasMinimumCategoriesPicked = () => ratedCategories && ratedCategories.length >= MINIMUM_RATED_CATEGORIES;

  const isStepValid = () => isRolePicked() && isYearPicked() && hasMinimumCategoriesPicked();

  return isStepValid;
};
