import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '../../../utils/utils';

import styles from './Dropdown.module.css';

const Dropdown = ({
  customStyles = '',
  label = null,
  options,
  placeholder = 'Choose an option',
  onChange,
  defaultValue = '',
}) => {
  const [activeOption, setActiveOption] = useState('');
  const handleOnChange = (e) => {
    const value = e.target.value;
    setActiveOption(value);
    onChange && onChange(value);
  };

  const selectedOption = (option) => (getIcon(option) ? `${getIcon(option)} ${option}` : option);

  const Option = ({ children }) => <option value={children.value}>{children.name}</option>;

  return (
    <div className={`${styles.wrapper} ${customStyles}`}>
      {label ? (
        <label htmlFor={`${label}-dropdown`} className={styles.label}>
          {label}
        </label>
      ) : null}
      <div className={styles.dropdownWrapper}>
        <select
          id={`${label}-dropdown`}
          onChange={handleOnChange}
          className={styles.select}
          value={activeOption || defaultValue}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </select>
        <div className={`${styles.dropdown} ${customStyles}`} aria-hidden>
          {(activeOption && selectedOption(activeOption)) ||
            (defaultValue && selectedOption(defaultValue)) ||
            (placeholder && selectedOption(placeholder))}
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  customStyles: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  placeholder: PropTypes.string.isRequired,
};

export default Dropdown;
