import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../shared';

import styles from './CompanyReviewConfirmationModal.module.css';

const CompanyReviewConfirmationModal = ({ onClose, companyId }) => {
  const [isReviewBeingSubmitted, setIsReviewBeingSubmitted] = useState(false);
  const history = useHistory();

  const fetchCompanyDetails = async (ratingsStep, reviewStep, previewStep) => {
    const payload = {
      role: ratingsStep.rolePicked,
      year: ratingsStep.yearPicked,
      ratings: [...ratingsStep.ratedCategories],
      review: {
        ...reviewStep,
        isRoleVisible: previewStep.isRoleVisible,
      },
      companyId,
    };

    try {
      setIsReviewBeingSubmitted(true);

      const response = await fetch(process.env.REACT_APP_SUBMIT_COMPANY_RATING, {
        method: 'POST',
        body: JSON.stringify(payload),
      });

      return response;
    } catch (error) {
      console.error('Server error: ', error);
    }
  };

  const handleSubmit = async () => {
    const ratingsStep = JSON.parse(sessionStorage.getItem('ratingsStep'));
    const reviewStep = JSON.parse(sessionStorage.getItem('reviewStep'));
    const previewStep = JSON.parse(sessionStorage.getItem('previewStep')) || true;

    const response = await fetchCompanyDetails(ratingsStep, reviewStep, previewStep);

    if (response.status === 200) {
      setIsReviewBeingSubmitted(false);
      onClose();
      history.push(`/review/${companyId}/success`);
    } else {
      console.error('Some error occurred, please try again');
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.modalTitle}>Confirmation</h3>
      <p className={styles.modalText}>
        Once your review is submitted it can’t be edited You can still view it and delete it though
        <br />
        <br />
        Are you ready to submit your review?
      </p>
      <div className={styles.buttonWrapper}>
        <Button noStyle customStyles={styles.cancel} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button customStyles={styles.submit} onClick={handleSubmit} isLoading={isReviewBeingSubmitted}>
          Submit
        </Button>
      </div>
    </div>
  );
};

CompanyReviewConfirmationModal.propTypes = {
  companyId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompanyReviewConfirmationModal;
