import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './Tabs.module.css';
import Link from '../link/Link';

const Tabs = ({ customStyles = '', children }) => {
  return <ul className={`${styles.tabs} ${customStyles}`}>{children}</ul>;
};

Tabs.Title = ({ activeTab, linkTo = null, onClick, customStyles = '', children }) => {
  const content = Array.isArray(children) ? children[0] : children;
  const sideNote = Array.isArray(children) ? children[1] : children;

  const isActive = content && activeTab.toLowerCase() === content.toLowerCase() ? styles['tab--active'] : '';

  if (linkTo) {
    return (
      <li className={`${styles.tab} ${customStyles} ${isActive}`} onClick={onClick ? () => onClick(content) : null}>
        <>
          <Link to={linkTo}>{content}</Link>
          {sideNote}
        </>
      </li>
    );
  }

  return <li className={`${styles.tab} ${isActive}`}>{content}</li>;
};

Tabs.propTypes = {
  customStyles: PropTypes.string,
};

Tabs.Title.propTypes = {
  activeTab: PropTypes.string.isRequired,
  customStyles: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
};

export default Tabs;
