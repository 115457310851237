import React from 'react';
import PropTypes from 'prop-types';

import styles from './Radio.module.css';

const Radio = ({ id, size = 18, name, customStyles = '', onChange, defaultChecked, children }) => (
  <div className={`${styles.wrapper} ${customStyles}`}>
    <span className={styles.radioWrapper}>
      <input
        type="radio"
        id={id}
        name={name}
        className={styles.radioInput}
        value={children}
        defaultChecked={defaultChecked}
        onChange={(e) => onChange(e.target.value)}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
      <span className={styles.radio} style={{ width: `${size}px`, height: `${size}px` }} />
    </span>
    <label htmlFor={id} className={styles.label}>
      {children}
    </label>
  </div>
);

Radio.propTypes = {
  children: PropTypes.string.isRequired,
  customStyles: PropTypes.string,
  defaultChecked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default Radio;
