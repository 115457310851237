import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, PreviewItem, Link } from '../../../components/shared';

import styles from './CompanyPreview.module.css';

const CompanyPreview = ({ onSubmit }) => {
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    const summaryStepData = JSON.parse(sessionStorage.getItem('summaryStep'));
    const localizationStepData = JSON.parse(sessionStorage.getItem('localizationStep'));
    const socialStepData = JSON.parse(sessionStorage.getItem('socialStep'));

    if (summaryStepData && localizationStepData && socialStepData) {
      setCompanyDetails({
        description: summaryStepData?.companyNameDescription,
        field: localizationStepData.companyField,
        language: localizationStepData.companyLanguage,
        linkedin: socialStepData?.companyLinkedIn,
        location: localizationStepData.companyLocation,
        name: summaryStepData.companyName,
        origin: localizationStepData.companyOrigin,
        size: localizationStepData.companySize,
        slogan: summaryStepData.companySlogan,
        twitter: socialStepData?.companyTwitter,
        website: socialStepData.companyWebsite,
      });
    }
  }, []);

  return (
    <>
      <PreviewItem label="Company Name">
        <span aria-hidden className={styles.icon}>
          🏢
        </span>
        {companyDetails?.name}
      </PreviewItem>
      <PreviewItem label="Slogan">
        <span aria-hidden className={styles.icon}>
          💡
        </span>
        {companyDetails?.slogan}
      </PreviewItem>
      {companyDetails?.description ? (
        <PreviewItem label="Description">
          <span aria-hidden className={styles.icon}>
            📝
          </span>
          {companyDetails?.description}
        </PreviewItem>
      ) : null}
      <PreviewItem label="Location">
        <span aria-hidden className={styles.icon}>
          🌎
        </span>
        {companyDetails?.location}
      </PreviewItem>
      <PreviewItem label="Field">
        <span aria-hidden className={styles.icon}>
          🚀
        </span>
        {companyDetails?.field}
      </PreviewItem>
      <PreviewItem label="Origin Year">
        <span aria-hidden className={styles.icon}>
          🎂
        </span>
        {companyDetails?.origin}
      </PreviewItem>
      <PreviewItem label="Company Size">
        <span aria-hidden className={styles.icon}>
          🏟
        </span>
        {companyDetails?.size}
      </PreviewItem>
      <PreviewItem label="Office Language">
        <span aria-hidden className={styles.icon}>
          🇪🇺
        </span>
        {companyDetails?.language}
      </PreviewItem>
      <PreviewItem label="Website">
        <span aria-hidden className={styles.icon}>
          🌐
        </span>
        {companyDetails?.website}
      </PreviewItem>
      {companyDetails?.linkedIn ? (
        <PreviewItem label="LinkedIn">
          <span aria-hidden className={styles.icon}>
            💼
          </span>
          {`@${companyDetails?.linkedIn}`}
        </PreviewItem>
      ) : null}
      {companyDetails?.twitter ? (
        <PreviewItem label="Twitter">
          <span aria-hidden className={styles.icon}>
            💬
          </span>
          {`@${companyDetails?.twitter}`}
        </PreviewItem>
      ) : null}
      <div className={styles.buttonWrapper}>
        <Link to="/new-company/social" customStyles={styles.backButton}>
          Back
        </Link>
        <Button onClick={onSubmit}>Submit</Button>
      </div>
    </>
  );
};

CompanyPreview.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CompanyPreview;
