import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './LoadingWheel.module.css';

const LoadingWheel = ({ size = 'small', hasInvertedColors, hasText, customStyles = '' }) => (
  <div className={`${styles.wrapper} ${styles[size]} ${hasInvertedColors ? styles.inverted : ''} ${customStyles}`}>
    <div className={`${styles.dot} ${styles[size]}`} />
  </div>
);

LoadingWheel.propTypes = {
  customStyles: PropTypes.string,
  hasInvertedColors: PropTypes.bool,
  hasText: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default LoadingWheel;
