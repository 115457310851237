import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Textbox from '../textbox/Textbox';
import Link from '../link/Link';

// import companies from '../../../mocks/companies.json';
import searchSvg from '../../../assets/icons/search.svg';
import styles from './CompanySearchBox.module.css';
import { LoadingDots } from '../loading';

const searchCompanies = async (query = '') => {
  const response = await fetch(process.env.REACT_APP_SEARCH_COMPANIES, {
    method: 'POST',
    body: JSON.stringify({ query }),
  });

  return await response.json();
};

const CompanySearchBox = ({ customStyles = '', linkTo, onClick }) => {
  const [searchResults, setSearchResults] = useState(null);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isResultsVisible, setIsResultsVisible] = useState(false);

  const handleSearchQuery = async (query) => {
    if (query.length < 3) {
      setIsResultsVisible(false);
      setSearchResults(null);
    }

    if (query.length >= 3) {
      setIsSearchLoading(true);
      setIsResultsVisible(true);

      // @TODO: Create a debounce function so we dont get too many requests in a row
      // const results = companies.filter((company) => company.details.name.toLowerCase().match(query.toLowerCase()));
      try {
        const searchResults = await searchCompanies(query);

        setSearchResults(searchResults);
        setIsSearchLoading(false);
        setIsResultsVisible(true);
      } catch (error) {
        setIsSearchLoading(false);

        console.error('Server error: ', error);
      }
    }
  };

  const handleOnFocus = () => {
    if (searchResults) {
      setIsResultsVisible(true);
    }
  };

  const handleOnBlur = (e) =>
    e.relatedTarget && e.relatedTarget.tagName.toLowerCase() === 'a' ? false : setIsResultsVisible(false);

  return (
    <div className={`${styles.wrapper} ${customStyles}`}>
      <Textbox
        icon={searchSvg}
        placeholder="Search"
        customStyles={styles.textbox}
        onChange={handleSearchQuery}
        onKeyDown={() => setIsResultsVisible(false)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {isResultsVisible ? (
        <ul className={styles.list}>
          {isSearchLoading ? (
            <li className={styles.link}>
              <LoadingDots size="medium" customStyles={styles.dots} />
            </li>
          ) : searchResults && searchResults.length <= 0 ? (
            <li className={`${styles.link} ${styles.noResults}`}>
              <span className={styles.companyName}>
                No results found
                <span className={styles.noResultsIcon} role="img" aria-label="UFO emoji">
                  🛸
                </span>
              </span>
            </li>
          ) : (
            searchResults.map((company, index) => (
              <li key={index} onClick={(e) => onClick && onClick(e)}>
                <Link to={`${linkTo}${company._id}`} customStyles={styles.link}>
                  <img
                    src={require(`../../../assets/logos/${company._id}_logo_200x200.png`)}
                    alt={`${company.name}'s logo`}
                    className={styles.icon}
                  />
                  <p className={styles.companyName}>{company.name}</p>
                </Link>
              </li>
            ))
          )}
        </ul>
      ) : null}
    </div>
  );
};

CompanySearchBox.propTypes = {
  customStyles: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CompanySearchBox;
