import React from 'react';
import PropTypes from 'prop-types';

import styles from './Textarea.module.css';

const Textarea = ({
  placeholder = null,
  resizable = false,
  customStyles = '',
  onChange,
  onBlur,
  children,
  label = null,
  id = null,
}) => {
  const handleOnChange = (e) => onChange && onChange(e.target.value);
  const handleOnBlur = (e) => onBlur && onBlur(e.target.value);

  return (
    <div>
      {label ? (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      ) : null}
      <textarea
        id={id}
        className={`${styles.textarea} ${resizable ? styles.resizable : ''} ${customStyles}`}
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        defaultValue={children}
      />
    </div>
  );
};

Textarea.propTypes = {
  children: PropTypes.string,
  customStyles: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  resizable: PropTypes.bool,
};

export default Textarea;
