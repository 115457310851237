import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './LoadingDots.module.css';

const LoadingDots = ({ hasInvertedColors, size = 'small', customStyles = '' }) => (
  <div className={`${styles.wrapper} ${styles[size]} ${hasInvertedColors ? styles.inverted : ''} ${customStyles}`}>
    <div className={`${styles.dot} ${styles[size]}`} />
    <div className={`${styles.dot} ${styles[size]}`} />
    <div className={`${styles.dot} ${styles[size]}`} />
  </div>
);

LoadingDots.propTypes = {
  customStyles: PropTypes.string,
  hasInvertedColors: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default LoadingDots;
