import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { sortBy } from '../../../utils/utils';
import Review from './review/Review';
import { LoadingWheel } from '../../../components/shared/loading';

import styles from './CompanyReviews.module.css';

const CompanyReviews = ({ companyId }) => {
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    const fetchCompanyReviews = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_COMPANY_REVIEWS, {
          method: 'POST',
          body: JSON.stringify({ companyId }),
        });
        const reviews = await response.json();

        setReviews(reviews);
      } catch (error) {
        console.error('Server error: ', error);
      }
    };

    fetchCompanyReviews();
  }, [companyId]);

  return (
    <div className={styles.wrapper}>
      {!reviews ? (
        <LoadingWheel size="medium" />
      ) : reviews.length > 0 ? (
        sortBy(reviews, 'upVotes', 'desc').map((review, index) =>
          review.text ? <Review key={index} review={review} /> : null
        )
      ) : (
        <p className={styles.noResults}>
          This company doesnt have enough reviews to visualize yet{' '}
          <span role="img" aria-label="magnifying glass emoji">
            🔍
          </span>
        </p>
      )}
    </div>
  );
};

CompanyReviews.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyReviews;
