import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/header/Header';
import Hero from '../../components/hero/Hero';
import { Form, Textbox, CompanySearchBox, Wrapper } from '../../components/shared';
// import Toolbar from '../../components/toolbar/Toolbar';
// import Button from '../../components/shared/button/Button';
import CompanyList from '../../components/company-list/CompanyList';
import { LoadingWheel } from '../../components/shared/loading';

import styles from './LandingPage.module.css';

const LandingPage = ({ onHandleModal }) => {
  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_COMPANIES_WITH_TOP_RATINGS, {
          method: 'POST',
          body: JSON.stringify({ totalLimit: 30 }),
        });
        const companies = await response.json();

        setCompanies(companies);
      } catch (error) {
        console.error('Server error: ', error);
      }
    };

    fetchCompanies();
  }, []);

  return (
    <div>
      <Header isFixed isUserLoggedIn={false} noLinks onHandleModal={onHandleModal} />
      <Hero alt="Sketch of two faceless people sitting down by a desk on a laptop">
        <div className={styles.heroContent}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>Honest Work</h1>
            <p className={styles.text}>
              Ut vel dictum sem, a pretium dui in malesuada enim velit esse cillum dolore eu fugiat nulla pariatur
            </p>
          </div>
          <div className={styles.signUpWrapper}>
            <h2 className={styles.title}>
              Create an account <small className={styles.smallText}>*it's free</small>
            </h2>
            <Form>
              <Textbox label="Username" id="signUpUsername" customStyles={styles.textbox} />
              <Textbox label="Password" id="signUpPassword" isPassword customStyles={styles.textbox} />
              {/* <Textbox
                placeholder="password"
                label="Password"
                id="signUpPassword"
                icon={searchSvg}
                customStyles={styles.textbox}
              /> */}
              {/* <Button value="Submit" onClick={() => console.log('Click!')} /> */}
            </Form>
          </div>
        </div>
      </Hero>
      <Wrapper customStyles={styles.toolbarContainer}>
        {/* <Toolbar search filter sort searchStyless={styles.toolbarSearch} /> */}
        {!companies ? (
          <LoadingWheel size="medium" />
        ) : (
          <>
            <div className={styles.searchStyles}>
              <CompanySearchBox placeholder="Search" linkTo="/company/" />
            </div>
            <CompanyList companies={companies} />
          </>
        )}
      </Wrapper>
    </div>
  );
};

LandingPage.propTypes = {
  onHandleModal: PropTypes.func.isRequired,
};

export default LandingPage;
