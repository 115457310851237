import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { truncateText } from '../../../utils/utils';
import { RatingItem } from '../../../components/shared';
import { LoadingWheel } from '../../../components/shared/loading';

import styles from './CompanyHappiness.module.css';

const CompanyHappiness = ({ companyId }) => {
  const [topReviews, setTopReviews] = useState(null);
  useEffect(() => {
    const fetchCompanyReviews = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_COMPANY_HAPPINESS, {
          method: 'POST',
          body: JSON.stringify({ companyId }),
        });
        const groupedReviews = await response.json();

        setTopReviews(groupedReviews);
      } catch (error) {
        console.error('Server error: ', error);
      }
    };

    fetchCompanyReviews();
  }, [companyId]);

  return (
    <div>
      {!topReviews ? (
        <LoadingWheel size="medium" />
      ) : Object.keys(topReviews).length > 0 ? (
        <div className={styles.wrapper}>
          {Object.keys(topReviews).map((reviewRole, index) => (
            <div key={index} className={styles.reviewsWrapper}>
              <h2 className={styles.title}>{reviewRole}s</h2>
              {topReviews[reviewRole].map((review, index) => (
                <Fragment key={index}>
                  <RatingItem
                    ratingItem={{ category: review?.ratings?.category, value: review?.ratings?.value }}
                    customStyles={styles.ratingItem}
                    circlesCustomStyles={styles.circlesWrapper}
                    tooltip={review?.ratings?.value}
                  />
                  <p to={`reviews/#${review?.url}`} className={styles.quote}>
                    {truncateText(review?.ratings?.review, 100)}
                  </p>
                </Fragment>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <p className={styles.noResults}>
          This company doesnt have enough reviews to visualize yet{' '}
          <span role="img" aria-label="magnifying glass emoji">
            🔍
          </span>
        </p>
      )}
    </div>
  );
};

CompanyHappiness.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyHappiness;
