import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RatingItem, Button, Textarea } from '../../../../components/shared';

import arrowDownSvg from '../../../../assets/icons/arrow-down-blue.svg';
import arrowRightSvg from '../../../../assets/icons/arrow-right-blue.svg';

import styles from './ExpandableRating.module.css';

const ExpandableRating = ({ category, onClick, onBlur, preFilledData = null }) => {
  const [rating, setRating] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const memoizedPrefillData = useMemo(() => preFilledData, [preFilledData]);

  const handleClick = (ratingValue) => {
    setRating(ratingValue);
    setIsExpanded(true);
    onClick(category, ratingValue);
  };

  const handleBlur = (reviewText) => {
    onBlur && onBlur(category, reviewText);
  };

  return (
    <div>
      <RatingItem
        direction="column"
        ratingItem={category}
        customStyles={styles.ratingItem}
        circlesCustomStyles={styles.circlesWrapper}
        size={22}
        isClickable
        defaultValue={memoizedPrefillData?.[category.category]}
        onClick={handleClick}
        isColorized={false}
      />
      {rating || memoizedPrefillData ? (
        <div className={styles.reviewWrapper}>
          <Button onClick={() => setIsExpanded(!isExpanded)} customStyles={styles.button} noStyle>
            <img src={isExpanded ? arrowDownSvg : arrowRightSvg} alt="arrow icon" aria-hidden />
          </Button>
          <Textarea
            customStyles={`${styles.textarea} ${isExpanded ? styles.expanded : ''}`}
            placeholder="Pros / Cons (optional)"
            onBlur={handleBlur}
          >
            {memoizedPrefillData?.review}
          </Textarea>
        </div>
      ) : null}
    </div>
  );
};

ExpandableRating.propTypes = {
  category: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  preFilledData: PropTypes.object,
};

export default ExpandableRating;
