import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './StepCounter.module.css';

const StepCounter = ({ placement = 'bottom', currentStep, steps, customStyles = '' }) => {
  const currentStepIndex = currentStep ? steps.indexOf(currentStep) : 0;
  const isStepActive = (index) => currentStepIndex === index;
  const isStepDone = (index) => currentStepIndex > index;

  return (
    <div className={`${styles.wrapper} ${customStyles}`}>
      {steps.map((step, index) => {
        return (
          <div key={index} className={styles.stepWrapper}>
            <div
              className={`${styles.circleWrapper} ${isStepActive(index) ? styles.active : ''} ${
                isStepDone(index) ? styles.done : ''
              }`}
            >
              {isStepActive(index) ? <div className={styles.innerCircle} /> : null}
            </div>
            <div className={styles.label}>{step}</div>
          </div>
        );
      })}
    </div>
  );
};

StepCounter.propTypes = {
  customStyles: PropTypes.string,
  currentStep: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom']),
  steps: PropTypes.array.isRequired,
};

export default StepCounter;
