import React from 'react';
import PropTypes from 'prop-types';

import styles from './CompanyDetails.module.css';

const DetailWrapper = ({ children }) => <dl className={styles.detailWrapper}>{children}</dl>;

const CompanyDetails = ({ companyDetails }) => (
  <div className={styles.wrapper}>
    <dl>
      <dt className={styles.label}>Description</dt>
      <dd className={styles.text}>{companyDetails.description}</dd>
    </dl>
    <div className={styles.detailsWrapper}>
      <div className={styles.leftColumn}>
        <DetailWrapper>
          <dt className={styles.label}>Field</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🚀
            </span>
            <span className={styles.detail}>{companyDetails.workField}</span>
          </dd>
        </DetailWrapper>
        <DetailWrapper>
          <dt className={styles.label}>Location</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🌎
            </span>
            <span className={styles.detail}>{companyDetails.location}</span>
          </dd>
        </DetailWrapper>
        <DetailWrapper>
          <dt className={styles.label}>Size</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🏢
            </span>
            <span className={styles.detail}>{companyDetails.size} people</span>
          </dd>
        </DetailWrapper>
        <DetailWrapper>
          <dt className={styles.label}>Overall Rating</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🏆
            </span>
            <span className={styles.detail}>{companyDetails.overallRating}</span>
          </dd>
        </DetailWrapper>
      </div>
      <div className={styles.rightColumn}>
        <DetailWrapper>
          <dt className={styles.label}>Origin</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🎂
            </span>
            <span className={styles.detail}>{companyDetails.origin}</span>
          </dd>
        </DetailWrapper>
        <DetailWrapper>
          <dt className={styles.label}>Official Language</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🇪🇺
            </span>
            <span className={styles.detail}>{companyDetails.language}</span>
          </dd>
        </DetailWrapper>
        <DetailWrapper>
          <dt className={styles.label}>Website</dt>
          <dd className={styles.textWrapper}>
            <span className={styles.icon} aria-hidden>
              🌐
            </span>
            <a href={companyDetails.website} className={`${styles.detail} ${styles.link}`}>
              {companyDetails.website}
            </a>
          </dd>
        </DetailWrapper>
        <DetailWrapper>
          <dt className={styles.label}>Socials</dt>
          <dd className={styles.textWrapper}>
            {companyDetails.socials.map((social, index) => (
              <div key={index} className={styles.social}>
                <span className={styles.icon} aria-hidden>
                  {social.name === 'linkedIn' ? '💼' : '💬'}
                </span>
                <a href={social.url} className={`${styles.detail} ${styles.link}`}>
                  {social.url}
                </a>
              </div>
            ))}
          </dd>
        </DetailWrapper>
      </div>
    </div>
  </div>
);

CompanyDetails.propTypes = {
  companyDetails: PropTypes.shape({
    description: PropTypes.string.isRequired,
    language: PropTypes.string,
    location: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    overallRating: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    socials: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    workField: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompanyDetails;
