import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { LandingPage, CompanyDetailsPage, CompanyReviewPage, CompanyCreatePage } from './pages';
import { Modal } from './components/shared';

function App() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [modalParams, setModalParams] = useState(null);

  const onHandleModalOpen = (isModalActive, id, params) => {
    if (!isModalActive) {
      return onHandleModalClose();
    }

    setModalId(id);
    setIsModalVisible(isModalActive);
    setModalParams(params);
  };

  const onHandleModalClose = () => {
    setIsModalVisible(false);
    setModalId(null);
  };

  return (
    <Router>
      {isModalVisible && modalId ? <Modal modalId={modalId} onClose={onHandleModalClose} params={modalParams} /> : null}
      <Switch>
        <Route path="/" exact>
          <LandingPage onHandleModal={onHandleModalOpen} />
        </Route>
        <Route path="/company/:companyId">
          <CompanyDetailsPage onHandleModal={onHandleModalOpen} />
        </Route>
        <Route path="/review/:companyId">
          <CompanyReviewPage onHandleModal={onHandleModalOpen} />
        </Route>
        <Route path="/new-company">
          <CompanyCreatePage onHandleModal={onHandleModalOpen} />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
