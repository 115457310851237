import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormValidation } from '../../../components/hooks';
import { Textbox, Button, Link } from '../../../components/shared';

import styles from './CompanySocial.module.css';

const CompanySocial = ({
  onSaveStepData,
  prefillData,
  updateLinkedIn,
  updateTwitter,
  updateWebsite,
  validationRules,
}) => {
  const [isStepValidated, handleFormValidation] = useFormValidation(validationRules);

  useEffect(() => {
    handleFormValidation();
  }, [handleFormValidation]);

  return (
    <>
      <Textbox
        label="Website"
        id="website"
        onChange={(val) => updateWebsite(val)}
        placeholder="https://..."
        defaultValue={prefillData?.companyWebsite}
        customStyles={styles.textbox}
      />
      <Textbox
        label="LinkedIn"
        id="linkedin"
        onChange={(val) => updateLinkedIn(val)}
        placeholder="@username"
        defaultValue={prefillData?.companyLinkedIn}
        customStyles={styles.textbox}
      />
      <Textbox
        label="Twitter"
        id="twitter"
        onChange={(val) => updateTwitter(val)}
        placeholder="@username"
        defaultValue={prefillData?.companyTwitter}
        customStyles={styles.textbox}
      />
      <div className={styles.buttonWrapper}>
        <Link to="/new-company/localization" customStyles={styles.backButton}>
          Back
        </Link>
        {!isStepValidated ? (
          <Button disabled onClick={handleFormValidation}>
            Next
          </Button>
        ) : (
          <Link to="/new-company/preview" onClick={onSaveStepData} customStyles={styles.nextButton}>
            Next
          </Link>
        )}
      </div>
    </>
  );
};

CompanySocial.propTypes = {
  onSaveStepData: PropTypes.func.isRequired,
  prefillData: PropTypes.shape({
    companyLinkedIn: PropTypes.string,
    companyTwitter: PropTypes.string,
    companyWebsite: PropTypes.string,
  }),
  updateLinkedIn: PropTypes.func.isRequired,
  updateTwitter: PropTypes.func.isRequired,
  updateWebsite: PropTypes.func.isRequired,
  validationRules: PropTypes.array.isRequired,
};

export default CompanySocial;
