import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Tooltip } from '../../../components/shared';
import ExpandableRating from './expandable-rating/ExpandableRating';
import { sortObjectArrayBy, indexOfObjectArrayKey } from '../../../utils/utils';
import {
  yearsInService,
  arrayToObject,
  splitCategories,
  updateCategoryRating,
  updateCategoryReview,
  ratingStepValidation,
} from './utils';

import { categoriesList, techRolesList } from '../../../data';

import styles from './RatingStep.module.css';

const RatingStep = ({ company, onChange, prefillData }) => {
  const memoizedPrefillData = useMemo(() => prefillData, [prefillData]);
  const [isYearTooltipActive, setIsYearTooltipActive] = useState(false);
  const [rolePicked, setRolePicked] = useState(memoizedPrefillData?.rolePicked || '');
  const [yearPicked, setYearPicked] = useState(memoizedPrefillData?.yearPicked || '');
  const [ratedCategories, setRatedCategories] = useState(memoizedPrefillData?.ratedCategories || []);
  const roleDropdown = sortObjectArrayBy(techRolesList, 'name', 'asc');
  const yearDropdown = sortObjectArrayBy(
    arrayToObject(yearsInService(company?.details?.origin), ['name', 'value']),
    'name',
    'desc'
  );
  const categories = useMemo(() => splitCategories(sortObjectArrayBy(categoriesList, 'category', 'asc')), []);

  useEffect(() => {
    if (rolePicked || yearPicked || ratedCategories.length > 0)
      onChange(ratingStepValidation(rolePicked, yearPicked, ratedCategories), {
        rolePicked,
        yearPicked,
        ratedCategories,
      });
  }, [rolePicked, yearPicked, ratedCategories, onChange]);

  const handleCategoryRated = (categoryDetails, value) => {
    const { category } = categoryDetails;
    const newCategories = updateCategoryRating(ratedCategories, category, value);

    setRatedCategories(newCategories);
  };

  const handleReviewUpdate = (categoryDetails, reviewText) => {
    const { category } = categoryDetails;
    const newCategories = updateCategoryReview(ratedCategories, category, reviewText);

    setRatedCategories(newCategories);
  };

  return (
    <div className={styles.columnsWrapper}>
      <div className={styles.leftColumn}>
        <Dropdown
          placeholder="Pick your role"
          options={roleDropdown}
          label="Your role"
          defaultValue={memoizedPrefillData?.rolePicked}
          onChange={(value) => setRolePicked(value)}
        />
        {categories.left.map((category, index) => (
          <ExpandableRating
            key={index}
            category={category}
            onClick={handleCategoryRated}
            onBlur={handleReviewUpdate}
            preFilledData={
              memoizedPrefillData?.ratedCategories[
                indexOfObjectArrayKey(memoizedPrefillData.ratedCategories, category.category)
              ]
            }
          />
        ))}
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.yearDropdownWrapper}>
          <Dropdown
            placeholder="Pick the year"
            options={yearDropdown}
            label="Year"
            defaultValue={memoizedPrefillData?.yearPicked}
            onChange={(value) => setYearPicked(value)}
          />
          <span
            className={styles.yearToolTipLabel}
            onMouseEnter={() => setIsYearTooltipActive(true)}
            onMouseLeave={() => setIsYearTooltipActive(false)}
          >
            ?
            <Tooltip customStyles={styles.yearToolTip} isVisible={isYearTooltipActive} position="top">
              year this review best applies to
            </Tooltip>
          </span>
        </div>
        {categories.right.map((category, index) => (
          <ExpandableRating
            key={index}
            category={category}
            onClick={handleCategoryRated}
            onBlur={handleReviewUpdate}
            preFilledData={
              memoizedPrefillData?.ratedCategories[
                indexOfObjectArrayKey(memoizedPrefillData.ratedCategories, category.category)
              ]
            }
          />
        ))}
      </div>
    </div>
  );
};

RatingStep.propTypes = {
  company: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  prefillData: PropTypes.object,
};

export default RatingStep;
