import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from '../shared/wrapper/Wrapper';
import heroSvg from '../../assets/images/hero/landing-animated.svg';

import styles from './Hero.module.css';

const Hero = ({ alt, children }) => (
  <>
    <img className={styles.heroBackground} alt={alt} src={heroSvg} />
    <Wrapper>
      <div className={styles.heroContent}>{children}</div>
    </Wrapper>
  </>
);

Hero.propTypes = {
  alt: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

export default Hero;
