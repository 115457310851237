import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, Redirect, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSlugFromUrl } from '../../utils/utils';
import { LoadingWheel } from '../../components/shared/loading';
import Header from '../../components/header/Header';
import { CompanyBanner, Wrapper, StepCounter } from '../../components/shared';
import CompanySummary from './company-summary/CompanySummary';
import CompanyLocalization from './company-localization/CompanyLocalization';
import CompanySocial from './company-social/CompanySocial';
import CompanyPreview from './company-preview/CompanyPreview';
import CompanySuccess from './company-success/CompanySuccess';

import styles from './CompanyCreatePage.module.css';

const CompanyCreatePage = ({ onHandleModal }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [randomBanner, setRandomBanner] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companySlogan, setCompanySlogan] = useState(null);
  const [companyDescription, setCompanyDescription] = useState(null);
  const [companyOrigin, setCompanyOrigin] = useState(null);
  const [companySize, setCompanySize] = useState(null);
  const [companyLanguage, setCompanyLanguage] = useState(null);
  const [companyLocation, setCompanyLocation] = useState(null);
  const [companyField, setCompanyField] = useState(null);
  const [companyWebsite, setCompanyWebsite] = useState(null);
  const [companyLinkedIn, setCompanyLinkedIn] = useState(null);
  const [companyTwitter, setCompanyTwitter] = useState(null);

  const [activeStep, setActiveStep] = useState('summary');
  const allSteps = ['summary', 'localization', 'social', 'preview'];
  const { pathname } = useLocation();

  useEffect(() => {
    const getUnsplashUrl = async () => {
      try {
        const response = await fetch('https://source.unsplash.com/featured/800x600?outdoor', {
          method: 'GET',
        });

        setRandomBanner(response.url);
      } catch (error) {}
    };

    getUnsplashUrl();
  }, []);

  useEffect(() => {
    const slug = getSlugFromUrl(pathname);
    const step = slug === 'new-company' ? 'summary' : slug;
    setActiveStep(step);
  }, [pathname]);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const saveSummaryData = () => {
    const summaryStep = {
      companyName,
      companySlogan,
      companyDescription,
    };
    sessionStorage.setItem('summaryStep', JSON.stringify(summaryStep));
    setHasUnsavedChanges(true);
  };

  const saveLocalizationData = () => {
    const localizationStep = {
      companyLocation,
      companyField,
      companyOrigin,
      companySize,
      companyLanguage,
    };
    sessionStorage.setItem('localizationStep', JSON.stringify(localizationStep));
  };

  const saveSocialData = () => {
    const socialStep = {
      companyWebsite,
      companyLinkedIn,
      companyTwitter,
    };

    sessionStorage.setItem('socialStep', JSON.stringify(socialStep));
  };

  const shouldShowLeavePagePrompt = (location) => {
    const regex = new RegExp(`/new-company`, 'g');
    const newCompanyPath = location.pathname.match(regex);

    if (hasUnsavedChanges && !newCompanyPath) {
      return 'Leave page? Your changes will not be saved';
    }

    return true;
  };

  return (
    <>
      <Header isFixed isUserLoggedIn={true} onHandleModal={onHandleModal} />
      {activeStep === 'success' ? null : !randomBanner ? (
        <LoadingWheel size="medium" />
      ) : (
        <CompanyBanner banner={randomBanner} companyDetails={{ name: companyName, slogan: companySlogan }} />
      )}
      <Wrapper customStyles={styles.content}>
        <Prompt message={(location) => shouldShowLeavePagePrompt(location)} />
        {activeStep !== 'success' ? <h2 className={styles.title}>Register Company</h2> : null}
        <Switch>
          <Route path="/new-company" exact>
            <CompanySummary
              prefillData={JSON.parse(sessionStorage.getItem('summaryStep'))}
              onSaveStepData={saveSummaryData}
              updateCompanyName={(val) => setCompanyName(val)}
              updateCompanySlogan={(val) => setCompanySlogan(val)}
              updateCompanyDescription={(val) => setCompanyDescription(val)}
              validationRules={[companyName, companySlogan]}
            />
          </Route>
          <Route path="/new-company/localization">
            <CompanyLocalization
              prefillData={JSON.parse(sessionStorage.getItem('localizationStep'))}
              onSaveStepData={saveLocalizationData}
              updateLocation={(val) => setCompanyLocation(val)}
              updateWorkField={(val) => setCompanyField(val)}
              updateOrigin={(val) => setCompanyOrigin(val)}
              updateCompanySize={(val) => setCompanySize(val)}
              updateLanguage={(val) => setCompanyLanguage(val)}
              validationRules={[companySize, companyLanguage, companyLocation, companyField]}
            />
          </Route>
          <Route path="/new-company/social">
            <CompanySocial
              updateWebsite={(val) => setCompanyWebsite(val)}
              updateLinkedIn={(val) => setCompanyLinkedIn(val)}
              updateTwitter={(val) => setCompanyTwitter(val)}
              prefillData={JSON.parse(sessionStorage.getItem('socialStep'))}
              onSaveStepData={saveSocialData}
              validationRules={[companyWebsite]}
            />
          </Route>
          <Route path="/new-company/preview">
            <CompanyPreview onSubmit={() => onHandleModal(true, 'NewCompanyConfirmationModal')} />
          </Route>
          <Route path="/new-company/success">
            <CompanySuccess />
          </Route>
          <Redirect to="/new-company" />
        </Switch>
        {activeStep !== 'success' ? (
          <div className={styles.stepWrapper}>
            <StepCounter steps={allSteps} currentStep={activeStep} />
          </div>
        ) : null}
      </Wrapper>
    </>
  );
};

CompanyCreatePage.propTypes = {
  onHandleModal: PropTypes.func.isRequired,
};

export default CompanyCreatePage;
