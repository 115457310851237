import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RatingCircles from '../rating-circles/RatingCircles';
import Tooltip from '../tooltip/Tooltip';
import { getIcon } from '../../../utils/utils';

import styles from './RatingItem.module.css';

const RatingItem = ({
  children,
  circlesCustomStyles = '',
  customStyles = '',
  defaultValue = null,
  direction = 'row',
  hoverEffect,
  isClickable = false,
  isColorized = true,
  onClick,
  ratingItem,
  size = 18,
  tooltip = false,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div className={`${styles.wrapper} ${styles[direction]} ${customStyles} ${hoverEffect ? styles.hover : ''}`}>
      <span className={styles.nameWrapper}>
        <span className={styles.icon}>{getIcon(ratingItem.category)}</span>
        <span className={styles.name}>{ratingItem.category}</span>
      </span>
      <span
        className={`${styles.value} ${circlesCustomStyles}`}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        <RatingCircles
          rating={ratingItem.value || defaultValue}
          isClickable={isClickable}
          onClick={onClick}
          isColorized={isColorized}
          customStyles={`${styles.circles} ${customStyles}`}
          size={size}
        />
        {tooltip ? (
          <Tooltip isVisible={isTooltipVisible} position="top" customStyles={styles.tooltip}>
            {tooltip}
          </Tooltip>
        ) : null}
      </span>
      {children}
    </div>
  );
};
RatingItem.propTypes = {
  children: PropTypes.object,
  circlesCustomStyles: PropTypes.string,
  customStyles: PropTypes.string,
  defaultValue: PropTypes.number,
  direction: PropTypes.oneOf(['row', 'column']),
  hoverEffect: PropTypes.bool,
  isClickable: PropTypes.bool,
  isColorized: PropTypes.bool,
  onClick: PropTypes.func,
  ratingItem: PropTypes.shape({
    category: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  size: PropTypes.number,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RatingItem;
