import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormValidation } from '../../../components/hooks';
import { Textbox, Button, Link, Textarea } from '../../../components/shared';

import styles from './CompanySummary.module.css';

const CompanySummary = ({
  prefillData,
  onSaveStepData,
  updateCompanyDescription,
  updateCompanyName,
  updateCompanySlogan,
  validationRules,
}) => {
  const [isStepValidated, handleFormValidation] = useFormValidation(validationRules);

  useEffect(() => {
    handleFormValidation();
  }, [handleFormValidation, updateCompanyName, updateCompanySlogan]);

  return (
    <>
      <Textbox
        label="Company Name"
        id="company-name"
        placeholder="Company"
        defaultValue={prefillData?.companyName}
        customStyles={styles.textbox}
        onChange={(val) => updateCompanyName(val)}
      />
      <Textbox
        label="Slogan"
        id="slogan"
        placeholder="Description"
        defaultValue={prefillData?.companySlogan}
        onChange={(val) => updateCompanySlogan(val)}
        customStyles={styles.textbox}
      />
      <Textarea
        id="long-description"
        label="Description"
        placeholder="Long description"
        customStyles={styles.textarea}
        onChange={(val) => updateCompanyDescription(val)}
        resizable
      >
        {prefillData?.companyDescription}
      </Textarea>
      <div className={styles.buttonWrapper}>
        {!isStepValidated ? (
          <Button disabled onClick={handleFormValidation}>
            Next
          </Button>
        ) : (
          <Link to="/new-company/localization" onClick={onSaveStepData} customStyles={styles.nextButton}>
            Next
          </Link>
        )}
      </div>
    </>
  );
};

CompanySummary.propTypes = {
  prefillData: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    companySlogan: PropTypes.string.isRequired,
    companyDescription: PropTypes.string,
  }),
  onSaveStepData: PropTypes.func.isRequired,
  updateCompanyDescription: PropTypes.func.isRequired,
  updateCompanyName: PropTypes.func.isRequired,
  updateCompanySlogan: PropTypes.func.isRequired,
  validationRules: PropTypes.array.isRequired,
};

export default CompanySummary;
