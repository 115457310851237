import React, { Fragment } from 'react';

const removeDashes = (text) => {
  const preText = text.substr(5);
  const finalText = preText.substr(0, preText.length - 5);

  return finalText;
};

export const formatReview = (text, boldStyle) => {
  const splitText = text.split(/\n/);

  return splitText.map((text, index) => {
    const trimmedText = text.trim();
    if (text.match(/-----(.*?)-----/)) {
      return (
        <Fragment key={index}>
          <span className={boldStyle}>{removeDashes(trimmedText)}</span>
          <br />
        </Fragment>
      );
    }

    if (trimmedText === '') {
      return (
        <Fragment key={index}>
          <br />
          <br />
        </Fragment>
      );
    }

    return text;
  });
};
