import React from 'react';
import PropTypes from 'prop-types';

import styles from './Button.module.css';
import { LoadingWheel } from '../loading';

const Button = ({
  value,
  customStyles = '',
  ariaLabel,
  disabled,
  linkStyle,
  isLoading = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  noStyle,
  children,
}) => (
  <button
    className={`${styles.button} ${linkStyle ? styles.linkStyle : ''} ${disabled ? styles.disabled : ''} ${
      noStyle ? styles.noStyle : ''
    } ${customStyles}`}
    onClick={(e) => !disabled && onClick(e)}
    aria-label={ariaLabel}
    onMouseEnter={() => onMouseEnter && onMouseEnter()}
    onMouseLeave={() => onMouseEnter && onMouseLeave()}
  >
    {isLoading ? <LoadingWheel size="small" hasInvertedColors customStyles={styles.loadingWheel} /> : value || children}
  </button>
);

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.string, PropTypes.object]),
  customStyles: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  noStyle: PropTypes.bool,
  value: PropTypes.string,
};

export default Button;
