import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useRouteMatch, useLocation, Switch, Route, Redirect } from 'react-router-dom';

import Header from '../../components/header/Header';
import { Wrapper, Tooltip, Tabs, CompanyBanner } from '../../components/shared';
import { getSlugFromUrl } from '../../utils/utils';
import CompanyRatings from './company-ratings/CompanyRatings';
import CompanyHappiness from './company-happiness/CompanyHappiness';
import CompanyReviews from './company-reviews/CompanyReviews';
import CompanyDetails from './company-details/CompanyDetails';
import { LoadingWheel } from '../../components/shared/loading';

import styles from './CompanyDetailsPage.module.css';

const CompanyDetailsPage = ({ onHandleModal }) => {
  const [company, setCompany] = useState(null);
  const [activeTab, setActiveTab] = useState('ratings');
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const { url } = useRouteMatch();
  const { companyId } = useParams();
  const { pathname } = useLocation();

  const tabs = ['ratings', 'happiness', 'reviews', 'details'];

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_GET_COMPANY_DETAILS, {
          method: 'POST',
          body: JSON.stringify({ companyId }),
        });
        const company = await response.json();

        setCompany(company);
      } catch (error) {
        console.error('Server error: ', error);
      }
    };

    fetchCompanyDetails();
  }, [companyId]);

  useEffect(() => {
    const tab = getSlugFromUrl(pathname);

    // compare the url path with the tabs to determine the active tab
    // maybe this should be extracted
    if (tabs.indexOf(tab) > -1) {
      setActiveTab(tab);
    }
  }, [pathname, tabs]);

  const showTabSideNote = () => (
    <span
      className={styles.tabSideNote}
      onMouseEnter={() => setIsTooltipActive(true)}
      onMouseLeave={() => setIsTooltipActive(false)}
    >
      ?
      <Tooltip customStyles={styles.tooltip} isVisible={isTooltipActive} position="bottom">
        best perks according to role
      </Tooltip>
    </span>
  );

  return (
    <>
      <Header isFixed isUserLoggedIn={true} onHandleModal={onHandleModal} />
      {!company ? (
        <LoadingWheel size="medium" />
      ) : (
        <>
          <CompanyBanner companyDetails={company.details} />
          <Wrapper customStyles={styles.content}>
            <Tabs customStyles={styles.tabs}>
              {tabs.map((tab, index) => (
                <Tabs.Title key={index} activeTab={activeTab} linkTo={`${url}/${tab}`}>
                  {tab}
                  {tab === 'happiness' ? showTabSideNote() : null}
                </Tabs.Title>
              ))}
            </Tabs>
            <div className={styles.detailsContent}>
              <Switch>
                <Route path={`${url}/ratings`}>
                  <CompanyRatings companyId={companyId} />
                </Route>
                <Route path={`${url}/happiness`}>
                  <CompanyHappiness companyId={companyId} />
                </Route>
                <Route path={`${url}/reviews`}>
                  <CompanyReviews companyId={companyId} />
                </Route>
                <Route path={`${url}/details`}>
                  <CompanyDetails companyDetails={company.details} />
                </Route>
                <Route path={url}>
                  <Redirect to={`${url}/ratings`} />
                </Route>
              </Switch>
            </div>
          </Wrapper>
        </>
      )}
    </>
  );
};

CompanyDetailsPage.propTypes = {
  onHandleModal: PropTypes.func.isRequired,
};

export default CompanyDetailsPage;
